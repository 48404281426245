(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberAppointmentEditController', MemberAppointmentEditController);
    MemberAppointmentEditController.$inject = ['$state', 'entity', '$scope', '$uibModalInstance', '$filter', 'OfficeService', 'MessageService'];

    function MemberAppointmentEditController($state, entity, $scope, $uibModalInstance, $filter, OfficeService, MessageService) {
        /**
         * Created by vimpans on 2019/7/30
         */
        var vm = $scope;

        vm.showDescription = false;

        vm.meeting = entity;
        vm.appointmentModel = {
            global:false,
            id: vm.meeting.id,
            itemId: vm.meeting.inProgressItem.id,
            subject: vm.meeting.inProgressItem.subject,
            numberOfParticipants: vm.meeting.inProgressItem.numberOfParticipants
        };

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.translateCycleType = function (type) {
            if (type === 'MONTH') {
                return '月';
            } else if (type === 'WEEK') {
                return '周';
            } else if (type === 'DAY') {
                return '日';
            }
        };

        vm.editAppointmentAction = function () {
            var tempModel = angular.copy(vm.appointmentModel);
            tempModel.date = $filter('date')(tempModel.dateShow, 'yyyy-MM-dd');
            tempModel.endTime = tempModel.endTime.split(' ')[0];
            OfficeService.updatePrice(tempModel, function (res) {
                var option = {
                    title: '是否确定更改预定',
                    content: '修改预定时间：' +
                    tempModel.date +
                    ' ' +
                    tempModel.startTime +
                    '-' +
                    tempModel.endTime +
                    ' 共' +
                    2 +
                    '小时',
                    cancel: '取消',
                    issue: '修改',
                    descTitle: '更改会议室说明',
                    descContent: '1.会议开始前3小时(不含)，免费修改。2.会议开始不足3小时，不支持修改。'
                };
                if (tempModel.global) {
                    option.content += ('<br>重复设定：每'+vm.translateCycleType(vm.meeting.cycleType)+'重复截止'+$filter('date')(vm.meeting.endDate,'yyyy-MM-dd'));
                }
                option.content += ('<br><br><span style="font-size: 20px">共计：<span style="color: red">￥<span style="font-size: 42px">'+res.data.amount.toFixed(2)+'</span></span></span>');
                MessageService.contentConfirm(option, function () {
                    OfficeService.update(tempModel, function (res) {
                        MessageService.success("会议室修改成功 ！");
                        $uibModalInstance.close();
                    }, function (error) {
                        MessageService.error("会议室修改失败 ! "+(error.data.detail||""));
                    })
                });
            }, function (error) {
                MessageService.error("会议室修改失败 ! "+(error.data.detail||""));
            })
        };

        //================================start time selector================================
        vm.now = new Date();
        vm.timeEnumList = [];
        vm.initStartTimeEnum = initStartTimeEnum;
        vm.initEndTimeEnum = initStartTimeEnum;
        vm.appointmentModel.dateShow = new Date();
        initTimeEnum();
        function initTimeEnum() {
            for (var i=0; i<24; i++) {
                vm.timeEnumList.push(i<10?'0'+i+':00':i+':00');
                vm.timeEnumList.push(i<10?'0'+i+':30':i+':30');
            }
        }
        vm.$watch('appointmentModel.dateShow', function (newVal) {
            initStartTimeEnum();
        });
        vm.startTimeEnum = [];
        vm.endTimeEnum = [];
        initStartTimeEnum();
        initEndTimeEnum();
        function initStartTimeEnum() {
            if (vm.appointmentModel.dateShow &&
                vm.appointmentModel.dateShow.toLocaleDateString()=== vm.now.toLocaleDateString()) {
                var now_h = vm.now.getHours();
                now_h = now_h<10?'0'+now_h:now_h.toString();
                var now_m = vm.now.getMinutes()>=30?'30':'00';
                var now_string = now_h+':'+now_m;
                var now_index = vm.timeEnumList.indexOf(now_string);
                vm.startTimeEnum =vm.timeEnumList.slice(now_index+1);
            } else {
                vm.startTimeEnum = vm.timeEnumList;
            }
        }
        function initEndTimeEnum() {
            if (vm.appointmentModel.startTime) {
                var start_index = vm.timeEnumList.indexOf(vm.appointmentModel.startTime);
                vm.endTimeEnum = vm.timeEnumList.slice(start_index+1);
                var nextTimeEnum = vm.timeEnumList.slice(0, start_index);
                vm.endTimeEnum = vm.endTimeEnum.concat(nextTimeEnum.map(function (val) {
                    return val+" (次日)"
                }));
            } else {
                vm.endTimeEnum = vm.timeEnumList;
            }
        }
        vm.sStartTime = function (item) {
            vm.appointmentModel.startTime=item;
            initEndTimeEnum()
        };
        vm.sEndTime = function (item) {
            vm.appointmentModel.endTime=item;
        };
        //================================end time selector==================================
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('RoomDetailController', RoomDetailController);
    RoomDetailController.$inject = ['$state', '$scope', '$stateParams', 'RoomService', '$uibModal'];

    function RoomDetailController($state, $scope, $stateParams, RoomService, $uibModal) {
        /**
         * Created by vimpans on 2019/7/8
         */
        var vm = $scope;

        vm.roomModel = null;
        vm.buildingModel = null;
        vm.map = null;
        vm.centerPoint = null;
        vm.local = null;
        vm.agencyContentTop = 0;
        vm.roomPage = 0;
        vm.statusValueList = ["NOTRENT","SHARED","RENTED"];
        vm.statusNameList = ["未租","共享","已租"];

        initRoomAction();
        addListener();
        function initRoomAction() {
            RoomService.findOne({id: $stateParams.id}, function (res) {
                if (!res || !res.data) {return}
                vm.roomModel = res.data;
                vm.buildingModel = vm.roomModel.building;
                vm.buildingModel.address = (vm.buildingModel.addrProvince||'')+(vm.buildingModel.addrCity||'')+(vm.buildingModel.addrDistrict||'')+(vm.buildingModel.addrAddress||'');
                if (vm.buildingModel.address) {
                    initMapAction();
                }
            })
        }

        function initMapAction(){
            if (!BMap) {return}
            vm.map = new BMap.Map("baiduMap");
            vm.map.addControl(new BMap.NavigationControl({type: BMAP_NAVIGATION_CONTROL_ZOOM} ));
            vm.map.enableScrollWheelZoom();
            vm.map.enableKeyboard();
            vm.map.enableDragging();
            vm.map.enableDoubleClickZoom();
            vm.local = new BMap.LocalSearch(vm.map, {renderOptions: {map: vm.map, autoViewport: false}});
            // addMapOverlay();
            addressToPoint(function (point) {
                vm.centerPoint = point;
                vm.map.centerAndZoom(point, 17);
                vm.map.addOverlay(new BMap.Marker(point));
            });
        }

        function addressToPoint(cb) {
            var geo = new BMap.Geocoder();
            geo.getPoint(vm.buildingModel.address, cb, vm.buildingModel.addrProvince);
        }
        vm.nearbySearch = function (keys) {
            if (!keys) {return}
            vm.local.clearResults();
            vm.local.searchNearby(keys,vm.centerPoint,1000);
        };

        function addMapOverlay() {
            var icon = new BMap.Icon('../image/aboutus-map-annotation.png', new BMap.Size(50, 45), {
                anchor: new BMap.Size(15, 50)
            });
            var mkr = new BMap.Marker(new BMap.Point(121.456618,31.232689), {
                icon: icon
            });
            vm.map.addOverlay(mkr);
        }

        vm.managerExtend = false;
        vm.extendManagerAction = function () {
            vm.managerExtend = !vm.managerExtend;
            calculateAgency();
        };
        function addListener() {
            window.onscroll = function (ev) {
                calculateAgency();
                vm.$apply();
            }
        }
        function calculateAgency() {
            var totalHeight = $('.agency-container')[0].clientHeight;
            var contentHeight = $('.agency-content')[0].clientHeight;
            var t = document.documentElement.scrollTop || document.body.scrollTop;
            vm.agencyContentTop = (t-284)>0 ? (t-284) : 0;
            if (contentHeight + vm.agencyContentTop > totalHeight) {
                vm.agencyContentTop = totalHeight-contentHeight-5;
            }
        }

        vm.swiper_animation = { transform: 'translateX(0)' };
        vm.swiper_countor = 0;
        function autoSwiper() {
            if (!vm.roomModel.previewImages.images || vm.roomModel.previewImages.images.length===0 || vm.roomModel.previewImages.images.length===1) {
                return;
            }
            vm.interval = $interval(function () {
                vm.swiper_countor++;
                if (vm.swiper_countor === vm.roomModel.previewImages.images.length) {
                    vm.swiper_countor = 0;
                }
                vm.swiper_animation.transform = 'translateX('+(-vm.swiper_countor * 830)+'px)';
            }, 5000);
        }
        vm.tapSwiperBtn = function (idex) {
            if (!vm.roomModel.previewImages.images || vm.roomModel.previewImages.images.length===0 || vm.roomModel.previewImages.images.length===1) {
                return;
            }
            $interval.cancel(vm.interval);
            vm.swiper_animation.transform = 'translateX('+(-idex * 830)+'px)';
            vm.swiper_countor = idex;
            autoSwiper();
        };
        vm.previousImageAction = function () {
            vm.swiper_countor--;
            vm.tapSwiperBtn(vm.swiper_countor);
        };
        vm.nextImageAction = function () {
            vm.swiper_countor++;
            vm.tapSwiperBtn(vm.swiper_countor);
        };

        vm.rooms_animation = {transform: 'translateX(0)'};
        vm.rooms_countor = 0;
        function roomAutoSwiper() {
            if (!vm.roomModel.roomImages.images || vm.roomModel.roomImages.images.length===0 || vm.roomModel.roomImages.images.length===1) {
                return;
            }
            vm.roomTnterval = $interval(function () {
                vm.rooms_countor++;
                if (vm.rooms_countor === vm.roomModel.roomImages.images.length) {
                    vm.rooms_countor = 0;
                }
                vm.rooms_animation.transform = 'translateX('+(-vm.rooms_countor * 830)+'px)';
            }, 5000);
        }
        vm.tapRoomSwiperBtn = function (idex) {
            if (!vm.roomModel.roomImages.images || vm.roomModel.roomImages.images.length===0 || vm.roomModel.roomImages.images.length===1) {
                return;
            }
            $interval.cancel(vm.roomTnterval);
            vm.rooms_animation.transform = 'translateX('+(-idex * 830)+'px)';
            vm.rooms_countor = idex;
            roomAutoSwiper();
        };
        vm.previousRoomImageAction = function () {
            vm.rooms_countor--;
            vm.tapRoomSwiperBtn(vm.rooms_countor);
        };
        vm.nextRoomImageAction = function () {
            vm.rooms_countor++;
            vm.tapRoomSwiperBtn(vm.rooms_countor);
        };

        vm.appointmentAction = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/appointment/appointment.html',
                controller: 'AppointmentController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {source: vm.buildingModel.name+'/'+vm.roomModel.name, buildingId: vm.buildingModel.id, managerName: vm.roomModel.managerName, managerPhone: vm.roomModel.managerPhone};
                    }
                }
            }).result.then(function () {

            });
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('gwApp')
        .filter('unique', unique);

    function unique() {
        return uniqueAction;
        function uniqueAction (list,keyName) {
            var output = [],
                keys = [];
            angular.forEach(list, function(item) {
                var key = item[keyName];
                if(keys.indexOf(key) === -1) {
                    keys.push(key);
                    output.push(item);
                }
            });
            return output;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('AppointmentService', AppointmentService);

    AppointmentService.$inject = ['$resource'];

    function AppointmentService($resource) {
        var service = $resource('api/arrangement/:param', {}, {
            'create': {method: 'POST', params: {param: 'create'}, isArray: false}
        });
        return service;
    }
})();

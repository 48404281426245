(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('CityManagerService', CityManagerService)
        .factory('RegionManagerService', RegionManagerService)
        .factory('BuildingManagerService', BuildingManagerService)
        .factory('RoomManagerService', RoomManagerService)
        .factory('FacilitiesManagerService', FacilitiesManagerService);

    CityManagerService.$inject = ['$resource'];
    RegionManagerService.$inject = ['$resource'];
    BuildingManagerService.$inject = ['$resource'];
    RoomManagerService.$inject = ['$resource'];
    FacilitiesManagerService.$inject = ['$resource'];

    function CityManagerService($resource) {
        return $resource('api/city/:param', {}, {
            'query': {method: 'GET', params: {param: 'findByPage'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'findAll': {method: 'GET', params: {param: 'findAll'}, isArray: false},
            'delete': {method: 'POST', params: {param: 'delete'}, isArray: false}
        });
    }
    function RegionManagerService($resource) {
        return $resource('api/region/:param', {}, {
            'query': {method: 'GET', params: {param: 'findByPage'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'findAll': {method: 'GET', params: {param: 'findAll'}, isArray: false},
            'findByCity': {method: 'GET', params: {param: 'findByCity'}, isArray: false},
            'delete': {method: 'POST', params: {param: 'delete'}, isArray: false}
        });
    }
    function BuildingManagerService($resource) {
        return $resource('api/building/:param', {}, {
            'query': {method: 'GET', params: {param: 'findByPage'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'update': {method: 'POST', params: {param: 'update'}, isArray: false},
            'findAll': {method: 'GET', params: {param: 'findAll'}, isArray: false},
            'findByArea': {method: 'GET', params: {param: 'findByArea'}, isArray: false},
            'delete': {method: 'GET', params: {param: 'delete'}, isArray: false},
            'findOne': {method: 'GET', params: {param: 'findOne'}, isArray: false},
            'findLevel': {method: 'GET', params: {param: 'getLevel'}, isArray: false}
        });
    }
    function RoomManagerService($resource) {
        return $resource('api/room/:param', {}, {
            'query': {method: 'GET', params: {param: 'findByBuilding'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'update': {method: 'POST', params: {param: 'update'}, isArray: false},
            'findAll': {method: 'GET', params: {param: 'findAll'}, isArray: false},
            'findOne': {method: 'GET', params: {param: 'findOne'}, isArray: false},
            'findNonPhantom': {method: 'GET', params: {param: 'findNonPhantom'}, isArray: false},
            'findConference': {method: 'GET', params: {param: 'findConference'}, isArray: false},
            'delete': {method: 'GET', params: {param: 'delete'}, isArray: false}
        });
    }
    function FacilitiesManagerService($resource) {
        return $resource('api/facility/:param', {}, {
            'query': {method: 'GET', params: {param: 'findByPage'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'update': {method: 'POST', params: {param: 'update'}, isArray: false},
            'findAll': {method: 'GET', params: {param: 'findAll'}, isArray: false},
            'delete': {method: 'POST', params: {param: 'delete'}, isArray: false},
            'disable': {method: 'GET', params: {param: 'disable'}, isArray: false},
            'enable': {method: 'GET', params: {param: 'enable'}, isArray: false}
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('RegisterController', RegisterController);
    RegisterController.$inject = ['$state', '$scope', 'VerificationService', '$interval', 'MemberAccountService', 'MessageService', '$rootScope', 'AuthServerProvider', '$uibModalInstance', '$window', '$uibModal'];

    function RegisterController($state, $scope, VerificationService, $interval, MemberAccountService, MessageService, $rootScope, AuthServerProvider, $uibModalInstance, $window, $uibModal) {
        /**
         * Created by vimpans on 2019/6/19
         */
        var vm = $scope;

        vm.agree = false;
        vm.agreementNegotiate = true;
        vm.canVerify = true;
        vm.counter = 0;

        vm.registerModel = {};

        vm.getVerification = function () {
            if (!vm.registerModel.phone) {return}
            VerificationService.get({phone:vm.registerModel.phone, type: 'REGISTRY'},function (res) {
                countDownAction();
            }, function (error) {
                MessageService.error("获取验证码失败 ！"+(error.data.detail||''));
            })
        };

        function countDownAction() {
            vm.canVerify = false;
            vm.counter = 60;
            var interval = $interval(function () {
                vm.counter --;
                if (vm.counter <= 0) {
                    vm.counter = 0;
                    vm.canVerify = true;
                    $interval.cancel(interval);
                }
            },1000);
        }

        vm.registerAction = function () {
            MemberAccountService.register(vm.registerModel, function (res) {
                AuthServerProvider.storeAuthenticationToken(res.data.id_token, false);
                $rootScope.$broadcast('authenticationSuccess');
                $uibModalInstance.close();
                $rootScope.$emit("requestLogin");
                MessageService.success("注册成功 ！");
            }, function (error) {
                console.log("error: ",error);
                MessageService.error("注册失败 ！"+(error.data.detail||''));
            });
        };

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };
        vm.requestLoginAction = function () {
            $uibModalInstance.dismiss();
            $rootScope.$emit("requestLogin");
        };

        vm.jumpNegotiation = function () {
            // $window.open($state.href('negotiation'),'_blank');
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/negotiation/negotiation.html',
                controller: 'NegotiationController',
                controllerAs: 'vm',
                size: 'negotiate',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {

            });
        }
    }
})();

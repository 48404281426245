(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ModuleController', ModuleController);
    ModuleController.$inject = ['$state', '$scope', 'Principal', 'AuthServerProvider', '$rootScope','$uibModal', 'CityManagerService', '$localStorage'];

    function ModuleController($state, $scope, Principal, AuthServerProvider, $rootScope,$uibModal, CityManagerService, $localStorage) {
        /**
         * Created by vimpans on 2019/6/17
         */
        var vm = $scope;

        vm.locationId = $localStorage.location_id;
        vm.locationName = $localStorage.location_name;

        vm.preview = false;

        //配置导航栏透明router表
        vm.transparentList = ['/home','/preview'];

        vm.transparent = false;

        $rootScope.$on('$stateChangeStart', function (event, to) {
            checkTransparent(to.url);
        });

        $rootScope.$on('requestLogin', function (event, to) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                size: 'normal',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {

            });
        });

        $rootScope.$on('requestRegister', function (event, to) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/register/register.html',
                controller: 'RegisterController',
                controllerAs: 'vm',
                size: 'normal',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {

            });
        });

        $rootScope.$on('userlogout', function (event, to) {
            vm.logoutAction();
        });
        $rootScope.$on('userlogin', function (event, to) {
            initUserInfo(true);
        });

        checkTransparent($state.current.url);
        function checkTransparent(state) {
            if (vm.transparentList.indexOf(state) !== -1) {
                checkScroll();
                window.onscroll = function (ev) {
                    checkScroll(true);
                };
                if (state === '/preview') {
                    vm.preview = true;
                }
            } else {
                vm.transparent = false;
                window.onscroll = null;
            }
        }
        function checkScroll(apply) {
            var t = document.documentElement.scrollTop || document.body.scrollTop;
            var total = document.documentElement.clientWidth * 0.41 - 85;
            vm.transparent = t<total;
            if (apply) {vm.$apply()}
        }

        vm.userModel = null;
        initUserInfo();
        function initUserInfo(force) {
            Principal.identity(force).then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
            })
        }

        vm.cityList = [];
        initCityAction();
        function initCityAction() {
            CityManagerService.findAll({}, function (res) {
                vm.cityList = res.data;
                if (vm.cityList && vm.cityList.length>0 && !$localStorage.location_id) {
                    $localStorage.location_id = vm.cityList[0].id;
                    $localStorage.location_name = vm.cityList[0].name;
                    vm.locationId = $localStorage.location_id;
                    vm.locationName = $localStorage.location_name;
                }
            });
        }

        vm.selCityAction = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/city-selector/city-selector.html',
                controller: 'CitySelectorController',
                controllerAs: 'vm',
                size: 'normal',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function (res) {
                $localStorage.location_id = res.id;
                $localStorage.location_name = res.name;
                vm.locationId = res.id;
                vm.locationName = res.name;
            });
        };

        vm.logoutAction = function () {
            AuthServerProvider.logout();
            vm.userModel = null;
        };

        vm.loginAction = function () {
            $rootScope.$emit("requestLogin");
        };

        vm.agentAction = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/appointment/appointment.html',
                controller: 'AppointmentController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {isAgent: true, source:'中介合作'};
                    }
                }
            }).result.then(function () {

            });
        }
    }
})();

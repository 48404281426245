(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('VerificationService', VerificationService);

    VerificationService.$inject = ['$resource'];

    function VerificationService($resource) {
        var service = $resource('api/:param', {}, {
            'get': {method: 'POST', params: {param: 'account/generateVCode'}, isArray: false}
        });
        return service;
    }
})();

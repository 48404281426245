(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('WgaccessService', WgaccessService);

    WgaccessService.$inject = ['$resource'];

    function WgaccessService($resource) {
        var service = $resource('api/wgaccess/:param', {}, {
            'queryByCompany': {method: 'GET', params: {param: 'getCompanyRecords'}, isArray: false}
        });
        return service;
    }
})();

(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('HomeConfigService', HomeConfigService);

    HomeConfigService.$inject = ['$resource'];

    function HomeConfigService($resource) {
        var service = $resource('api/home/:param', {}, {
            'findPublish': {method: 'GET', params: {param: 'findPublish'}, isArray: false},
            'findSave': {method: 'GET', params: {param: 'findSave'}, isArray: false}
        });
        return service;
    }
})();

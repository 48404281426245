(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('CompanyService', CompanyService);

    CompanyService.$inject = ['$resource'];

    function CompanyService($resource) {
        var service = $resource('api/company/:param', {}, {
            'findOne': {method: 'GET', params: {param: 'findEffectiveOffice'}, isArray: false},
            'getWifi': {method: 'GET', params: {param: 'getWifi'}, isArray: false}
        });
        return service;
    }
})();

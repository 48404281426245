(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('EmployeeService', EmployeeService);

    EmployeeService.$inject = ['$resource'];

    function EmployeeService($resource) {
        var service = $resource('api/employee/:param', {}, {
            'account': {method: 'GET', params: {param: 'accountInfo'}, isArray: false}
        });
        return service;
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberResetPasswordController', MemberResetPasswordController);
    MemberResetPasswordController.$inject = ['$state', '$scope', 'MemberAccountService', 'MessageService', '$uibModalInstance'];

    function MemberResetPasswordController($state, $scope, MemberAccountService, MessageService, $uibModalInstance) {
        /**
         * Created by vimpans on 2019/8/13
         */
        var vm = $scope;

        vm.resetModel = {};

        vm.resetPassword = function () {
            MemberAccountService.resetPassword(vm.resetModel, function (res) {
                MessageService.success("密码修改成功 !");
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error("密码修改失败 "+(error.data.detail||""));
            })
        };

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('LoginController', LoginController);
    LoginController.$inject = ['$state', '$scope', 'MemberAccountService', '$rootScope', 'MessageService', 'AuthServerProvider', '$uibModalInstance', '$uibModal'];

    function LoginController($state, $scope, MemberAccountService, $rootScope, MessageService, AuthServerProvider, $uibModalInstance, $uibModal) {
        /**
         * Created by vimpans on 2019/6/25
         */
        var vm = $scope;

        vm.loginModel = {rememberMe:false};

        vm.logining = false;
        vm.loginAction = function () {
            if (vm.logining) {return}
            vm.logining = true;
            MemberAccountService.login(vm.loginModel, function (res) {
                MessageService.success("登录成功 ！");
                AuthServerProvider.storeAuthenticationToken(res.id_token, vm.loginModel.rememberMe);
                // $rootScope.$broadcast('authenticationSuccess');
                $uibModalInstance.dismiss();
                $rootScope.$emit('userlogin');
                vm.logining = false;
            }, function (error) {
                MessageService.error("登录失败 ！"+(error.data.detail||''));
                vm.logining = false;
            });
        };

        vm.keyupAction = function (e) {
            if (e.keyCode === 13 && vm.loginModel.username && vm.loginModel.password) {
                vm.loginAction();
            }
        };

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.requestRegisterAction = function () {
            $uibModalInstance.dismiss();
            $rootScope.$emit("requestRegister");
        };

        vm.forgotPassword = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/member-center/member-forgot-password.html',
                controller: 'MemberForgotPasswordController',
                controllerAs: 'vm',
                size: 'normal',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {

            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('gwApp')
        .factory('Funcs', Funcs);

    Funcs.$inject = ['Upload', 'MessageService'];

    function Funcs(Upload, MessageService) {
        var method = {};

        /**
         * 文件选择器
         * accept: 接受文件类型（数组）
         * cb: 成功返回
         * ecd: 错误返回
         * */
        method.fileSelection = function (accept, cb, ecb) {
            if (!accept || !cb) {return;}
            var as = accept.join(',');
            var input = $('<input type="file" accept='+as+' />');
            input.click();
            input.on('change',function () {
                var file = input[0].files[0];
                if (!file) {return;}
                if (accept.indexOf(file.type) === -1) {
                    if (ecb) {
                        ecb("文件格式不支持 !");
                    }
                    return;
                }
                cb(file);
            })
        };

        method.imageUpload = function (cb) {
            method.fileSelection(["image/png","image/jpg","image/jpeg"], function (file) {
                // if (file.size() > )
                //TODO 限制上传文件大小
                Upload.upload({
                    url: 'api/files/user/upload',
                    data: {file: file}
                }).then(function (resp) {
                    if (resp && resp.data && resp.data.data && resp.data.data.path) {
                        resp.data.data.imageUrl = "api/files?accessPath="+resp.data.data.path;
                        cb(resp.data.data);
                    } else {
                        MessageService.error("图片上传失败 !");
                    }
                });
            }, function (error) {
                MessageService.error(error);
            })
        };

        return method;
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberCenterController', MemberCenterController);
    MemberCenterController.$inject = ['$state', '$scope', '$uibModal', 'EmployeeService', 'CompanyService', 'OfficeService', 'Principal', '$interval', 'MessageService', '$filter', 'AuthServerProvider', 'BuildingService'];

    function MemberCenterController($state, $scope, $uibModal, EmployeeService, CompanyService, OfficeService, Principal, $interval, MessageService, $filter, AuthServerProvider, BuildingService) {
        /**
         * Created by vimpans on 2019/7/17
         */
        var vm = $scope;

        vm.userModel = null;
        vm.employeeModel = null;
        vm.companyModel = null;
        vm.defaultBuilding = null;

        vm.meetingModel = null;
        vm.offices = [];

        userAccountInfo();
        function userAccountInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                if (vm.userModel.employeeId) {
                    employeeInfo(vm.userModel.employeeId);
                } else {
                    BuildingService.getOne({}, function (res) {
                        vm.defaultBuilding = res.data;
                    })
                }
            })
        }
        function employeeInfo(id) {
            EmployeeService.account({id:id}, function (res) {
                if (res.data && res.data.companyId) {
                    vm.employeeModel = res.data;
                    vm.employeeModel.roomQuotaString = '本月已用'+(vm.employeeModel.roomQuota.consumed||0)+'元，剩余'+(vm.employeeModel.roomQuota.available||0)+'元，截止'+getCurrentMonthLastDay()+'有效。<br>注：抵用券由平台配置，仅供会议室结算抵扣费用，不可提现，不使用过期自动失效，不可叠加。';
                    companyInfo(res.data.companyId);
                }
            })
        }

        function getCurrentMonthLastDay() {
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month;
            var day = new Date(year, month, 0);
            return  year + '年' + month + '月' + day.getDate() + '日'
        }

        function companyInfo(id) {
            CompanyService.findOne({companyId: id}, function (res) {
                vm.companyModel = res.data;
                vm.offices = vm.companyModel.offices||[];
                vm.offices = vm.offices.map(function (value, index) {
                    var initial = new Date(value.initialTime);
                    var now = new Date();
                    var rentDays = (now-initial)/86400000;
                    rentDays = Math.ceil(rentDays);
                    rentDays = rentDays<0?0:rentDays;
                    value.rentDays = rentDays;
                    value.extendArea = false;
                    value.meetings = [];
                    value.managerExtend = false;
                    return value;
                }).sort(function (a,b) {
                    return a.id - b.id;
                });
                if (vm.offices && vm.offices.length > 0) {
                    vm.extendMeetingAtIndex(vm.offices[0])
                }
            })
        }

        vm.extendManagerAction = function (office) {
            office.managerExtend = !office.managerExtend;
        };

        vm.extendMeetingAtIndex = function (office) {
            office.extendArea = !office.extendArea;
            if (office.extendArea && (!office.meetings || office.meetings.length===0)) {
                meetingInfo(office);
            }
        };

        function meetingInfo(office) {
            OfficeService.getByOffice({officeId: office.id, companyId: vm.companyModel.id}, function (res) {
                office.meetings = res.data.filter(function (val) {
                    return val.inProgressItem;
                });
            });
        }

        vm.appointmentMeetingAction = function (office) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/member-center/member-appointment.html',
                controller: 'MemberAppointmentController',
                controllerAs: 'vm',
                size: 'appointment',
                resolve: {
                    entity: function () {
                        return {companyId: vm.companyModel.id, office: angular.copy(office), buildingIds: vm.offices.map(function (value) { return value.buildingId })};
                    }
                }
            }).result.then(function () {
                meetingInfo(office);
            });
        };

        // vm.rooms_animation = {transform: 'translateX(0)'};
        // vm.rooms_countor = 0;
        // function roomAutoSwiper() {
        //     if (!vm.companyModel || vm.companyModel.rooms ||) {
        //         return;
        //     }
        //     vm.roomTnterval = $interval(function () {
        //         vm.rooms_countor++;
        //         if (vm.rooms_countor === vm.buildingModel.roomImages.images.length) {
        //             vm.rooms_countor = 0;
        //         }
        //         vm.rooms_animation.transform = 'translateX('+(-vm.rooms_countor * 830)+'px)';
        //     }, 5000);
        // }
        // vm.tapRoomSwiperBtn = function (idex) {
        //     if (!vm.buildingModel.roomImages.images || vm.buildingModel.roomImages.images.length===0 || vm.buildingModel.roomImages.images.length===1) {
        //         return;
        //     }
        //     $interval.cancel(vm.roomTnterval);
        //     vm.rooms_animation.transform = 'translateX('+(-idex * 830)+'px)';
        //     vm.rooms_countor = idex;
        //     roomAutoSwiper();
        // };
        // vm.previousRoomImageAction = function () {
        //     vm.rooms_countor--;
        //     vm.tapRoomSwiperBtn(vm.rooms_countor);
        // };
        // vm.nextRoomImageAction = function () {
        //     vm.rooms_countor++;
        //     vm.tapRoomSwiperBtn(vm.rooms_countor);
        // };

        vm.appointmentRecord = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/member-center/member-appointment-record.html',
                controller: 'MemberAppointmentRecordController',
                controllerAs: 'vm',
                size: 'appointment',
                resolve: {
                    entity: function () {
                        return {companyId: vm.companyModel.id};
                    }
                }
            }).result.then(function () {

            });
        };

        vm.accessRecords = function (office) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/member-center/member-access-record.html',
                controller: 'MemberAccessRecordsController',
                controllerAs: 'vm',
                size: 'appointment',
                resolve: {
                    entity: function () {
                        return {companyId: vm.companyModel.id, buildingId: office.buildingId};
                    }
                }
            }).result.then(function () {

            });
        };


        vm.memberWiFi = function (office) {
            office.companyId = vm.companyModel.id;
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/member-center/member-wifi.html',
                controller: 'MemberWifiController',
                controllerAs: 'vm',
                size: 'normal',
                resolve: {
                    entity: function () {
                        return office;
                    }
                }
            }).result.then(function () {

            });
        };
        vm.memberAccountInfo = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/member-center/member-info.html',
                controller: 'MemberInfoController',
                controllerAs: 'vm',
                size: 'account',
                resolve: {
                    entity: function () {
                        return {

                        };
                    }
                }
            }).result.then(function () {

            });
        };
        vm.cancelAppointment = function (meeting, office) {

            meeting.timeType = timeString2Type(meeting.inProgressItem.startTime, meeting.inProgressItem.amount);

            if (meeting.repeat) {
                $uibModal.open({
                    animation: true,
                    keyboard: false,
                    backdrop: 'static',
                    templateUrl: 'app/module/member-center/member-appointment-cancel.html',
                    controller: 'MemberAppointmentCancelController',
                    controllerAs: 'vm',
                    size: 'normal',
                    resolve: {
                        entity: function () {
                            return meeting;
                        }
                    }
                }).result.then(function () {
                    meetingInfo(office);
                })
            } else {
                var content = '预定日期：' +
                    $filter('date')(meeting.date, 'yyyy-MM-dd') +
                    ' ' +
                    $filter('date')(meeting.startTime, 'HH:mm') +
                    '-' +
                    $filter('date')(meeting.endTime, 'HH:mm') +
                    ' 共' +
                    meeting.durationInHours +
                    '小时' + (meeting.timeType.insertHtml||"");
                MessageService.contentConfirm({
                    title: '是否确定取消预定',
                    content: content,
                    cancel: '保留预定',
                    issue: '取消预定',
                    descTitle: '取消会议室说明',
                    descContent: '1.会议开始前3小时(不含)，免费取消。2.会议开始前1-3小时，按单次预订总费用45%计。3.会议开始前不足1(含)小时，将扣除预定用时总费用(预定用时*单价)。4.取消预定产生的费用不可使用配额额度扣费。'
                }, function () {
                    OfficeService.cancelled({
                        id: meeting.id,
                        itemId: meeting.inProgressItem.id
                    }, function (res) {
                        MessageService.success("会议室预定 取消成功!");
                        meetingInfo(office);
                    }, function (error) {
                        MessageService.error("会议室预定 取消失败"+(error.data.detail||''));
                    })
                })
            }
        };
        vm.editAppointment = function (meeting, office) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/member-center/member-appointment-edit.html',
                controller: 'MemberAppointmentEditController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return meeting;
                    }
                }
            }).result.then(function () {
                meetingInfo(office);
            })
        };

        vm.logoutAction = function () {
            MessageService.confirm("确认退出登录 ？", function () {
                AuthServerProvider.logout();
                window.location.reload();
            })
        };

        vm.appointmentAction = function (source) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/appointment/appointment.html',
                controller: 'AppointmentController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {source: source};
                    }
                }
            }).result.then(function () {

            });
        };

        function timeString2Type (str, amount) {
            var start = new Date(str);
            var now = new Date();
            var subtractRes = (start - now) / (1000 * 60 * 60);
            var res = {type: 'Normal'};
            if (subtractRes < 3 && subtractRes > 1) {
                var subtractHour = start.getHours()-now.getHours();
                var subtractMinutes = start.getMinutes()-now.getMinutes();
                if (subtractMinutes < 0) {
                    subtractHour = subtractHour-1;
                    subtractMinutes = 60+subtractMinutes;
                }
                res.type = "UserCancelLess3H";
                var s = subtractHour+"小时"+(subtractMinutes===0?"":(subtractMinutes+"分钟"));
                res.insertHtml = '<br><br>距离会议开始有'+s+'<br>需从余额扣除45%的预扣款(<span style="color: red">'+(amount*0.45)+'</span>元)';
            } else if (subtractRes < 1 && subtractRes > 0) {
                var subtractMinutes = (start.getMinutes())-now.getMinutes();
                if (subtractMinutes < 0) {
                    subtractMinutes = 60+subtractMinutes;
                }
                res.type = "UserCancelLess1H";
                var s = subtractMinutes===0?"不到1分钟":(subtractMinutes+"分钟");
                res.insertHtml = '<br><br>距离会议开始有'+s+'<br>需从余额扣除全部的预扣款(<span style="color: red">'+amount+'</span>元)';
            } else if (subtractRes < 0) {
                res.type = "Running";
                res.insertHtml = '<br><br>会议已经开始<br>需从余额扣除全部的预扣款(<span style="color: red">'+amount+'</span>元)';
            }
            return res;
        }
    }
})();

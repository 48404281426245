(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('RoomService', RoomService);

    RoomService.$inject = ['$resource'];

    function RoomService($resource) {
        var service = $resource('api/room/:param', {}, {
            'search': {method: 'GET', params: {param: 'search'}, isArray: false},
            'filtration': {method: 'GET', params: {param: 'filtration'}, isArray: false},
            'findOne': {method: 'GET', params: {param: 'findOne'}, isArray: false}
        });
        return service;
    }
})();

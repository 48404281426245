(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('AppointmentController', AppointmentController);
    AppointmentController.$inject = ['$state', 'entity', '$scope', '$uibModalInstance', 'VerificationService', '$interval', 'AppointmentService', 'Principal', '$filter', 'MessageService', '$localStorage', '$window', '$uibModal', 'MemberService', '$timeout'];

    function AppointmentController($state, entity, $scope, $uibModalInstance, VerificationService, $interval, AppointmentService, Principal, $filter, MessageService, $localStorage, $window, $uibModal, MemberService, $timeout) {
        /**
         * Created by vimpans on 2019/7/9
         */
        var vm = $scope;

        vm.cityId = $localStorage.location_id;
        vm.cityName = $localStorage.location_name;

        vm.dateSel = false;
        vm.agreementNegotiate = true;
        vm.canVerify = true;
        vm.counter = 0;
        vm.isAgent = false;
        vm.appointmentModel = {source:"首页", cityId: vm.cityId};

        if (entity) {
            if (entity.cityId) {
                vm.appointmentModel.cityId = entity.cityId;
            }
            vm.appointmentModel.regionId = entity.regionId;
            vm.appointmentModel.buildingId = entity.buildingId;
            vm.appointmentModel.managerName = entity.managerName;
            vm.appointmentModel.managerPhone = entity.managerPhone;
            vm.appointmentModel.source = entity.source;
            vm.isAgent = entity.isAgent||false;
            vm.appointmentModel.agent = vm.isAgent;
        }

        vm.datePicker = { date: {startDate: moment(), endDate:  moment()}, time:'08:00 - 21:00' };

        vm.startDateSel = false;
        vm.startTimeSel = false;
        vm.endDateSel = false;
        vm.endTimeSel = false;
        vm.now = new Date();
        // vm.datePicker.date.startDate = vm.now;
        // vm.datePicker.date.endDate = vm.now;
        vm.nowString = vm.now.toLocaleDateString();
        vm.dateRangeOptions = {
            pickerClasses: 'daterangepicker-customer',
            parentEl: "#date-range-picker-parent",
            autoApply: true,
            locale: {
                applyLabel: "确定",
                cancelLabel: '取消',
                separator: ' - ',
                format: "YYYY-MM-DD",
                daysOfWeek: ['日', '一', '二', '三', '四', '五','六'],
                monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
            }
        };

        vm.userModel = null;
        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account || !account.data) {return}
                vm.userModel = account.data;
                vm.appointmentModel.phone = vm.userModel.phone;
                vm.appointmentModel.fullname = vm.userModel.fullname;
            })
        }

        vm.$watch('appointmentModel.startDateShow', function (newVal) {
            if (newVal && !vm.appointmentModel.startTimeShow) {
                vm.startDateSel = false;
                vm.startTimeSel = true;
                vm.endDateSel = false;
                vm.endTimeSel = false;
            }
        });
        vm.$watch('appointmentModel.endTimeShow', function (newVal) {
            if (newVal && !vm.appointmentModel.endTimeShow) {
                vm.startDateSel = false;
                vm.startTimeSel = false;
                vm.endDateSel = false;
                vm.endTimeSel = true;
            }
        });

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.getVerification = function () {
            if (!vm.appointmentModel.phone) {return}
            VerificationService.get({phone:vm.appointmentModel.phone, type: 'REGISTRY'},function (res) {
                countDownAction();
            }, function (error) {
                MessageService.error("获取验证码失败 ！"+(error.data.detail||''));
            })
        };

        function countDownAction() {
            vm.canVerify = false;
            vm.counter = 60;
            var interval = $interval(function () {
                vm.counter --;
                if (vm.counter <= 0) {
                    vm.counter = 0;
                    vm.canVerify = true;
                    $interval.cancel(interval);
                }
            },1000);
        }

        vm.saveAction = function () {
            vm.appointmentModel.startDate = $filter('date')(vm.datePicker.date.startDate, 'yyyy-MM-dd');
            vm.appointmentModel.endDate = $filter('date')(vm.datePicker.date.endDate, 'yyyy-MM-dd');
            vm.appointmentModel.startTime = vm.issueSelector.startH+':'+vm.issueSelector.startM;
            vm.appointmentModel.endTime = vm.issueSelector.endH+':'+vm.issueSelector.endM;
            AppointmentService.create(vm.appointmentModel, function (res) {
                MessageService.success("恭喜预约成功，工作人员会在指定的时间段内联系你！");
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error("预约失败"+(error.data.detail||''));
            })
        };
        // '00','01','02','03','04','05','06','07',
        // '00','01','02','03','04','05','06','07',
        vm.startHours = ['08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
        vm.startMinutes = ['00','10','20','30','40','50'];
        vm.endHours = ['08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
        vm.endMinutes = ['00','10','20','30','40','50'];
        vm.timeSelector = {startH: '08', startM: '00', endH: '21', endM: '00'};
        vm.timeSelectorError = false;
        vm.issueSelector = angular.copy(vm.timeSelector);
        vm.startHSel = function (idex) {
            vm.timeSelector.startH = vm.startHours[idex];
            event.stopPropagation();
        };
        vm.startMSel = function (idex) {
            vm.timeSelector.startM = vm.startMinutes[idex];
            event.stopPropagation();
        };
        vm.endHSel = function (idex) {
            vm.timeSelector.endH = vm.endHours[idex];
            event.stopPropagation();
        };
        vm.endMSel = function (idex) {
            vm.timeSelector.endM = vm.endMinutes[idex];
            event.stopPropagation();
            // var startH = parseInt(vm.timeSelector.startH);
            // var startM = parseInt(vm.timeSelector.startM);
            // var endH = parseInt(vm.timeSelector.endH);
            // var endM = parseInt(vm.timeSelector.endM);
            // if (endH < startH) {
            //     event.stopPropagation();
            //     vm.timeSelectorError = true;
            //     return;
            // }
            // if (endH === startH) {
            //     if (endM <= startM) {
            //         event.stopPropagation();
            //         vm.timeSelectorError = true;
            //         return;
            //     }
            // }
            // vm.issueSelector = angular.copy(vm.timeSelector);
            // vm.datePicker.time = vm.timeSelector.startH+':'+vm.timeSelector.startM+' - '+vm.timeSelector.endH+':'+vm.timeSelector.endM;
            // vm.timeSelector = angular.copy(vm.issueSelector);
        };
        vm.allTimeSel = function () {
            var startH = parseInt(vm.timeSelector.startH);
            var startM = parseInt(vm.timeSelector.startM);
            var endH = parseInt(vm.timeSelector.endH);
            var endM = parseInt(vm.timeSelector.endM);
            if (endH < startH) {
                event.stopPropagation();
                vm.timeSelectorError = true;
                return;
            }
            if (endH === startH) {
                if (endM <= startM) {
                    event.stopPropagation();
                    vm.timeSelectorError = true;
                    return;
                }
            }
            vm.issueSelector = angular.copy(vm.timeSelector);
            vm.datePicker.time = vm.timeSelector.startH+':'+vm.timeSelector.startM+' - '+vm.timeSelector.endH+':'+vm.timeSelector.endM;
            vm.timeSelector = angular.copy(vm.issueSelector);
            // vm.timeSelectorError = false;
            // vm.issueSelector = {startH: '08', startM: '00', endH: '23', endM: '50'};
            // vm.timeSelector = angular.copy(vm.issueSelector);
            // vm.datePicker.time = vm.timeSelector.startH+':'+vm.timeSelector.startM+' - '+vm.timeSelector.endH+':'+vm.timeSelector.endM;
        };
        vm.timeSelectAction = function () {
            vm.timeSelectorError = false;
            vm.timeSelector = angular.copy(vm.issueSelector);
        };

        vm.jumpNegotiation = function () {
            // $window.open($state.href('negotiation'),'_blank');
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/negotiation/negotiation.html',
                controller: 'NegotiationController',
                controllerAs: 'vm',
                size: 'negotiate',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {

            });
        };

        vm.phoneExists = false;
        vm.pcTimeout = null;
        vm.phoneChangeAction = function () {
            if (!vm.pcTimeout && vm.appointmentModel.phone) {
                vm.pcTimeout = $timeout(function () {
                    MemberService.check({login: vm.appointmentModel.phone}, function (res) {
                        vm.phoneExists = !!res.data.exists;
                    }, function () {vm.phoneExists = false;});
                    vm.pcTimeout = null;
                }, 800);
            } else {
                $timeout.cancel(vm.pcTimeout);
                vm.pcTimeout = null;
            }
        }
    }
})();

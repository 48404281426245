/**
 * Modify: @Nathan
 * Date: 2018/04/26
 * Detail: Need Bootstrap
 */
angular.module('waterfall', ['ngSanitize'])

    .factory('waterfall', function () {
        'use strict';

        // find the distances of all upstream path
        function upstream(node) {
            if (node.upstream)
                return node.upstream;

            // if this is a head node
            if (node.previous.length === 0)
                return node.upstream = [0];

            node.upstream = [];
            node.previous.forEach(function (previous) {
                upstream(previous).forEach(function (distance) {
                    node.upstream.push(distance + 1);
                });
            });
            return node.upstream;
        }

        // find the distances of all downstream path
        function downstream(node) {
            if (node.downstream)
                return node.downstream;

            // if this is a tail node
            if (node.next.length === 0)
                return node.downstream = [0];

            node.downstream = [];
            node.next.forEach(function (next) {
                downstream(next).forEach(function (distance) {
                    if (node.downstream.indexOf(distance) === -1)
                        node.downstream.push(distance + 1);
                });
            });
            return node.downstream;
        }

        function calculateScore(node) {
            node.score = node.upstream.reduce(function (a, b) {
                return a + b;
            }) + node.downstream.reduce(function (a, b) {
                return a + b;
            });
            node.scoreUp = node.upstream.reduce(function (a, b) {
                return a + b;
            });
            node.scoreDown = node.downstream.reduce(function (a, b) {
                return a + b;
            });
        }

        function buildOptions(options) {
            return {
                hide: options && typeof options.hide !== 'undefined' ? options.hide : true,
                node: {
                    background: options && options.node && typeof options.node.background !== 'undefined' ? options.node.background : '#000',
                    color: options && options.node && typeof options.node.color !== 'undefined' ? options.node.color : '#fff',
                    width: options && options.node && typeof options.node.width !== 'undefined' ? options.node.width : 180,
                    height: options && options.node && typeof options.node.height !== 'undefined' ? options.node.height : 30,
                    add: options && options.node && typeof options.node.add !== 'undefined' ? options.node.add : null,
                    remove: options && options.node && typeof options.node.remove !== 'undefined' ? options.node.remove : null,
                    ok: options && options.node && typeof options.node.ok !== 'undefined' ? options.node.ok : null,
                    cancel: options && options.node && typeof options.node.cancel !== 'undefined' ? options.node.cancel : null,
                    onclick: options && options.node && typeof options.node.onclick !== 'undefined' ? options.node.onclick : null,
                    mouseenter: options && options.node && typeof options.node.mouseenter !== 'undefined' ? options.node.mouseenter : null,
                    mouseleave: options && options.node && typeof options.node.mouseleave !== 'undefined' ? options.node.mouseleave : null,
                    keyup: options && options.node && typeof options.node.keyup !== 'undefined' ? options.node.keyup : null,
                    dblclick: options && options.node && typeof options.node.dblclick !== 'undefined' ? options.node.dblclick : null,
                    synchronize: options && options.node && typeof options.node.synchronize !== 'undefined' ? options.node.synchronize : null,
                    margin: {
                        x: options && options.node && options.node.margin && typeof options.node.margin.x !== 'undefined' ? options.node.margin.x : 20,
                        y: options && options.node && options.node.margin && typeof options.node.margin.y !== 'undefined' ? options.node.margin.y : 10
                    }
                }
            };
        }

        // empty
        function isEmpty(target) {
            return target === null || target === undefined || target === '';
        }

        var service = function (flow, options) {

            // default options
            options = options || buildOptions({});

            var index = {}, key;
            var nodes = [];
            // map nodes to index
            for (key in flow) {
                index[key] = {
                    id: key,
                    isActive: isEmpty(flow[key].isActive) ? false : flow[key].isActive,  // 是否为激活状态
                    isEdit: isEmpty(flow[key].isEdit) ? false : flow[key].isEdit,  // 是否为编辑状态
                    canAdd: isEmpty(flow[key].canAdd) ? false : flow[key].canAdd,  // 是否存在新增功能
                    html: flow[key].html || (flow[key].title ? flow[key].title.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;') : ''),
                    class: flow[key].class || '',
                    color: flow[key].color || undefined,
                    background: flow[key].background || undefined,
                    needBorder: flow[key].needBorder || false,
                    visible: flow[key].visible || flow[key].visible === undefined,
                    next: Array.isArray(flow[key].next) ? flow[key].next.slice() : [],
                    previous: []
                };
            }

            if (Object.keys(index).length === 0)
                return nodes;

            // relate nodes
            for (key in index) {
                var node = index[key];
                node.next.forEach(function (id, i, list) {
                    // build the relationships

                    var next = list[i] = index[id];
                    if (next.previous)
                        next.previous.push(node);
                    else
                        next.previous = [node];
                });

                // add node to nodes array
                nodes.push(node);
            }

            // remove hidden nodes
            if (options.hide) {
                for (key in index) {
                    var node = index[key];

                    if (!node.visible) {
                        node.next.forEach(function (next) {
                            // remove self from next nodes
                            next.previous.splice(next.previous.indexOf(node), 1);

                            // add next nodes to previous nodes
                            node.previous.forEach(function (previous) {
                                if (previous.next.indexOf(next) === -1) {
                                    previous.next.push(next);
                                }
                            });
                        });

                        node.previous.forEach(function (previous) {
                            // remove self from previous nodes
                            previous.next.splice(previous.next.indexOf(node), 1);

                            // add previous nodes to next nodes
                            node.next.forEach(function (next) {
                                if (next.previous.indexOf(previous) === -1)
                                    next.previous.push(previous);
                            });
                        });

                        // remove self from the array
                        nodes.splice(nodes.indexOf(node), 1);

                    }
                }
            }

            // calculate distances
            for (var i in nodes) {
                var node = nodes[i];
                upstream(node);
                downstream(node);
                // calculateX(node); // calculate x
                calculateScore(node); // calculate score from total upstream and downstream
            }

            // sort by score
            nodes.sort(function (a, b) {
                if (a.score === 1 && b.score === 1) {
                    return 1;
                } else {
                    return a.score < b.score ? 1 : -1;
                }
            });

            // assign y values, beginning with the lowest score
            // calculateY(nodes[0], 0);

            return nodes;
        };

        service.buildOptions = buildOptions;

        return service;
    })

    .directive('waterfall', ['waterfall', function (waterfall) {
        return {
            // 被声明的方式
            restrict: 'A',
            // 内容
            template: '' +
            '<svg class="waterfall" ng-attr-width="{{width}}" ng-attr-height="{{height}}">\n' +
            '    <g ng-attr-transform="translate({{options.node.width / -2 + options.node.margin.x}}, {{0}})">\n' +
            '        <g ng-repeat="node in nodes track by node.id" ng-attr-transform="translate({{node.x}}, {{node.y}})" ng-click="options.node.onclick(node,nodes);" ng-dblclick="options.node.dblclick(node,nodes);" style="outline: none;">\n' +
            '            <foreignObject\n' +
            '				ng-attr-width="{{options.node.width}}"\n' +
            '				ng-attr-height="{{options.node.height}}"\n' +
            '				ng-attr-rx="{{options.node.radius}}"\n' +
            '				ng-attr-ry="{{options.node.radius}}"\n' +
            '            >\n' +
            // '                <img class="svg-remove" src="content/images/remove.png" ng-if="!node.isEdit && node.canAdd" ng-click="options.node.remove(node);" style="float: right;margin-bottom: -8px;margin-right: -8px;outline: none;"/>' +
            '                <div id="{{node.id}}" ng-attr-style="\n' +
            '                    width: {{options.node.width}}px;\n' +
            '                    height: {{options.node.height}}px;\n' +
            '                    line-height: {{options.node.height}}px;\n' +
            '                    background: {{node.background == undefined ? options.node.background : node.background}};\n' +
            '                    color: {{node.color == undefined ? options.node.color : node.color}};\n' +
            '                " ng-class="{true:\'product-border\',false:\'component-border\'}[node.needBorder]"' +
            '                  ng-mouseenter="options.node.mouseenter(node)" ng-mouseleave="options.node.mouseleave(node)">\n' +
            '                    <input ng-keyup="options.node.keyup($event, node)" class="n_inputStyle" ng-model="node.html"\n' +
            '                           ng-if="node.isEdit" input-select focus-me="node.isEdit" ng-change="options.node.synchronize(node)" \n' +
            '                           style="font-size: 14px;width: 70%;float: left;background: #fff !important;padding: 0 10px;font-family: \'微软雅黑\' !important;" autofocus />\n' +
            '                    <span ng-if="node.isEdit" style="padding: 0;color: #669BC1;float: right;width: 30%;text-align: center;outline: none;font-family: \'微软雅黑\' !important;font-size: 14px;background: #E9F5FF;" ng-click="options.node.ok(node);">保存</span>\n' +
            '                    <span ng-if="!node.isEdit" ng-bind-html="node.html" style="margin: 0 10px;float: left;font-family: \'微软雅黑\' !important;font-size: 14px;"></span>\n' +
            '                    <div ng-if="!node.isEdit && node.canAdd" ng-click="options.node.add(node);" style="float: right;padding: 0 10px;outline: none;border-radius: 0;margin-top: 2px;line-height: 28px;" ng-class="{\'svg-border-left\': node.isActive}">\n' +
            '                        <img class="plus" src="content/images/plus_active.png" ng-if="!node.isEdit && node.canAdd"/>\n' +
            '                    </div>\n' +
            // '                    <div ng-if="!node.isEdit && node.canAdd" ng-click="options.node.remove(node);" style="float: right;padding: 0 10px;outline: none;">\n' +
            // '                        <img class="minus" src="content/images/minus_active.png" ng-if="!node.isEdit && node.canAdd"/>\n' +
            // '                    </div>\n' +
            '                    <span ng-if="node.isActive && !node.isEdit" style="padding: 0 15px;color: #669BC1;float: right;outline: none;font-family: \'微软雅黑\' !important;font-size: 14px;" ng-click="options.node.remove(node);">删除</span>\n' +
            '                </div>\n' +
            '            </foreignObject>\n' +
            '        </g>\n' +
            '        <path stroke-dasharray="5,5" stroke="red" \n' +
            '			ng-repeat="link in links"\n' +
            '			ng-attr-d="\n' +
            '				M {{link.source.x}} {{link.source.y}}\n' +
            '				Q {{link.q1[0][0]}} {{link.q1[0][1]}},\n' +
            '					{{link.q1[1][0]}} {{link.q1[1][1]}}\n' +
            '				Q {{link.q2[0][0]}} {{link.q2[0][1]}},\n' +
            '					{{link.q2[1][0]}} {{link.q2[1][1]}}\n' +
            '				T {{link.target.x}} {{link.target.y}}"\n' +
            '        />\n' +
            '    </g>\n' +
            '</svg>\n',
            require: 'ngModel',
            // 创建一个全新的作用域[不继承且隔离]
            // flow 与元素中 ng-model 父级作用域变量绑定
            // opts 与元素中属性 waterfall 指定的内容一致
            scope: {
                flow: '=ngModel',
                opts: '=waterfall'
            },
            controller: ['$scope', '$element', '$attrs', '$parse', function ($scope, $element, $attrs, $parse) {
                function calculateX(node) {
                    node.x = (Math.max.apply(Math, node.upstream) + 0.5) * ($scope.options.node.width + $scope.options.node.margin.x * 2);

                    // calculate canvas width
                    $scope.width = Math.max($scope.width, node.x);
                }

                function calculateY(node, level, n) {
                    if (node.level != null)
                        return;

                    node.level = level;
                    node.y = level * ($scope.options.node.height + $scope.options.node.margin.y * 2);

                    // 临时处理方法[40为向下偏离量]
                    if(node.id.indexOf('product_') === -1) {
                        node.y += $scope.options.node.height;    // 8 -- 新加删除 x 的偏移量
                    }

                    if(node.next!=null&&node.next.length>0){
                        for(var i=0;i<node.next.length;i++){
                            node.next[i].y = (i+1)* ($scope.options.node.height + $scope.options.node.margin.y * 2);
                        }
                    }

                    // node.next.sort(function (a, b) {
                    //     // put tail nodes on top
                    //     if (a.scoreDown === 0)
                    //         return 1;
                    //     if (b.scoreDown === 0)
                    //         return -1;

                    //     // upstream sort
                    //     return a.scoreUp < b.scoreUp ? 1 : -1;
                    // }).forEach(function (next, index) {
                    //     calculateY(next, level + index, node);
                    // });

                    // node.previous.sort(function (a, b) {
                    //     // put head nodes on top
                    //     if (a.scoreUp === 0)
                    //         return 1;
                    //     if (b.scoreUp === 0)
                    //         return -1;

                    //     // downstream sort
                    //     return a.scoreDown < b.scoreDown ? 1 : -1;
                    // }).forEach(function (previous, index) {
                    //     calculateY(previous, level + index, node);
                    // });

                    // calculate canvas height
                    $scope.height = Math.max($scope.height, -node.y);
                }

                // build the waterfall
                // $scope.$watch(watchExpression, listener, objectEquality)
                // - watchExpression: 监听对象
                // - listener: 监听对象变化时回调，接收参数(newValue, oldValue, scope)
                // - objectEquality: true -> 检查所监控的对象中每一个属性的变化
                // opts -> results[0]; flow -> results[1]
                $scope.$watch('[opts, flow]', function (results) {
                    if (!results[0] || !results[1])
                        return;

                    $scope.options = waterfall.buildOptions(results[0]);

                    $scope.nodes = waterfall(results[1], $scope.options);

                    var size = $scope.nodes.length - 1;
                    var height = size * $scope.options.node.height + (size - 1) * 20;
                    $scope.links = [];

                    $scope.width = 0;
                    $scope.height = 0;

                    // calculate x values
                    for (i in $scope.nodes) {
                        calculateX($scope.nodes[i]);
                    }

                    // assign y values, beginning with the lowest score
                    calculateY($scope.nodes[0], 0);

                    // build links
                    for (i in $scope.nodes) {
                        var node = $scope.nodes[i];
                        node.color = node.color === undefined ? undefined : node.color;
                        node.next.forEach(function (next) {
                            var l = {
                                // source: {
                                //     x: node.x + $scope.options.node.width,
                                //     y: node.y + $scope.options.node.height / 2
                                // },
                                source: {
                                    x: node.x + $scope.options.node.width / 2,
                                    y: node.y + $scope.options.node.height
                                },
                                target: {
                                    x: next.x,
                                    y: next.y + $scope.options.node.height / 2
                                }
                            };

                            var offset = Math.min(Math.abs(l.target.y - l.source.y), $scope.options.node.margin.x) * ((l.target.y - l.source.y < 0) ? 1 : -1);

                            // the x middle
                            var c = (l.source.x + l.target.x) / 2;

                            // bezier points and handles
                            // l.q1 = [[c, l.source.y], [c, l.source.y - offset]];
                            // l.q2 = [[c, l.target.y + 2 * offset], [c, l.target.y + offset]];
                            l.q1 = [[l.source.x, l.source.y], [l.source.x, l.source.y]];
                            l.q2 = [[l.source.x, l.target.y], [l.source.x, l.target.y]];

                            $scope.links.push(l);
                        });
                    }

                    // 重新排序
                    // for(var i=1;i<=parseInt($scope.nodes.length/2);i++){
                    //     var y = $scope.nodes[i].y;
                    //     $scope.nodes[i].y = $scope.nodes[$scope.nodes.length-i].y;
                    //     $scope.nodes[$scope.nodes.length-i].y = y;
                    // }

                    // pad the canvas
                    $scope.width += $scope.options.node.width;
                    $scope.height = height + 60;  // 暂时添加80增加间隔
                }, true);

            }]
        };
    }]);

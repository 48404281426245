(function() {
    'use strict';
    angular
        .module('gwApp')
        .directive('focusMe', focusMe);

    function focusMe() {
        return {
            restrict: 'A',
            scope: {
                focusMe: '='
            },
            link: function (scope, element, attr, ngModel) {
                scope.$watch('focusMe', function (val) {
                    if (val) {
                        element[0].focus();
                    }
                });
            }
        };
    }
})();

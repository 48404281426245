(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('ProjectCache', ProjectCache);

    ProjectCache.$inject = ['$cacheFactory'];

    function ProjectCache ($cacheFactory) {
        return $cacheFactory('project');
    }
})();

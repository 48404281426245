(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('OfficeService', OfficeService)
        .factory('OfficeItemService', OfficeItemService);

    OfficeService.$inject = ['$resource'];
    OfficeItemService.$inject = ['$resource'];

    function OfficeService($resource) {
        var service = $resource('api/bookJob/:param', {}, {
            'findByOffice': {method: 'GET', params: {param: 'findByOffice'}, isArray: false},
            'getByOffice': {method: 'GET', params: {param: 'getByOffice'}, isArray: false},
            'getPrice': {method: 'POST', params: {param: 'getPrice'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'update': {method: 'POST', params: {param: 'update'}, isArray: false},
            'cancelled': {method: 'POST', params: {param: 'cancelled'}, isArray: false},
            'cancelPrice': {method: 'POST', params: {param: 'getCancelledPrice'}, isArray: false},
            'updatePrice': {method: 'POST', params: {param: 'getUpdatePrice'}, isArray: false}
        });
        return service;
    }

    function OfficeItemService($resource) {
        var service = $resource('api/bookItem/:param', {}, {
            'queryCalenderItems': {method: 'GET', params: {param: 'queryCalenderItems'}, isArray: false},
            'queryByCompany': {method: 'GET', params: {param: 'queryByCompany'}, isArray: false}
        });
        return service;
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('NegotiationController', NegotiationController);
    NegotiationController.$inject = ['$state', '$scope', '$uibModalInstance'];

    function NegotiationController($state, $scope, $uibModalInstance) {
        /**
         * Created by vimpans on 2019/8/21
         */
        var vm = $scope;

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberAppointmentCancelController', MemberAppointmentCancelController);
    MemberAppointmentCancelController.$inject = ['$state', 'entity', '$scope', '$uibModalInstance', 'OfficeService', 'MessageService'];

    function MemberAppointmentCancelController($state, entity, $scope, $uibModalInstance, OfficeService, MessageService) {
        /**
         * Created by vimpans on 2019/7/30
         */
        var vm = $scope;

        vm.showDescription = false;

        vm.selectAll = false;
        vm.meeting = entity;

        vm.insertAction = function () {
            $('#time-type').html(vm.meeting.timeType.insertHtml);
        };

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.translateCycleType = function (type) {
            if (type === 'MONTH') {
                return '月';
            } else if (type === 'WEEK') {
                return '周';
            } else if (type === 'DAY') {
                return '日';
            }
        };

        vm.cancelAction = function () {
            OfficeService.cancelled({
                id: vm.meeting.id,
                itemId: vm.meeting.inProgressItem.id,
                global: vm.selectAll
            }, function (res) {
                MessageService.success("会议室预定 取消成功!");
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error("会议室预定 取消失败"+(error.data.detail?(','+error.data.detail):''));
            })
        }
    }
})();

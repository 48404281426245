(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('PromotionController', PromotionController);
    PromotionController.$inject = ['$state', '$scope', 'HomeConfigService'];

    function PromotionController($state, $scope, HomeConfigService) {
        /**
         * Created by vimpans on 2019/7/16
         */
        var vm = $scope;

        vm.advertiseModel = {};

        initDataAction();
        function initDataAction() {
            HomeConfigService.findPublish({}, function (res) {
                var homeModel = res.data;

                vm.advertiseModel.mainTitle = homeModel.mainTitle;
                vm.advertiseModel.subHead = homeModel.subHead;
                vm.advertiseModel.content = homeModel.advertiseIntroduce;

                $("#advertise-container").html(vm.advertiseModel.content);

                if (!homeModel) {return}
                for (var i = 0; i<homeModel.resources.length; i++) {
                    var resource = homeModel.resources[i];
                    if (resource.type === "ADVERTISE") {
                        vm.advertiseModel.imageUrl = resource.category.images[0].imageUrl;
                    }
                }
            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .directive('vpCheckbox', vpCheckbox);

    function vpCheckbox() {
        return {
            restrict: 'E',
            terminal: false,
            replace:true,
            template: function(tElement, tAttrs){
                var template =  "<span ng-click='vpRadioClick()' class='checkbox-container'>" +
                    "<span class='iconfont radio-style' ng-class=\"{'icon-checkbox-active':vpModel,'icon-checkbox-normal':!vpModel}\"></span>" +
                    "<span style='color: #333333'>"+tElement[0].innerText+"</span>" +
                    "</span>";
                return template;
            },
            scope: {
                vpModel: '=',
                vpChange: '&'
            },
            link: function ($scope) {
                var s = $scope;
                if (!s.vpModel) {s.vpModel = false}
                s.vpRadioClick = function () {
                    s.vpModel = !s.vpModel;
                    setTimeout(function () {
                        if (s.vpChange) {
                            s.vpChange();
                        }
                    });
                };
            }
        };
    }
})();

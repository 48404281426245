(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CitySelectorController', CitySelectorController);
    CitySelectorController.$inject = ['$state', '$scope', '$localStorage', 'CityManagerService', '$uibModalInstance'];

    function CitySelectorController($state, $scope, $localStorage, CityManagerService, $uibModalInstance) {
        /**
         * Created by vimpans on 2019/8/19
         */
        var vm = $scope;

        vm.locationId = $localStorage.location_id;
        vm.locationName = $localStorage.location_name;

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.cityList = [];
        initCityAction();
        function initCityAction() {
            CityManagerService.findAll({}, function (res) {
                vm.cityList = res.data;
            });
        }

        vm.selCity = function (city) {
            if (vm.locationId === city.id) {return}
            vm.locationId = city.id;
            vm.locationName = city.name;
        };

        vm.saveAction = function () {
            $uibModalInstance.close({id:vm.locationId, name:vm.locationName});
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('gwApp')
        .filter('sub', sub);

    function sub() {
        return function (iArr, x) {
            if (!iArr) {return []}
            if (!x) {return iArr}
            return iArr.slice(0, x>iArr.length?iArr.length:x);
        };
    }
})();

(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('MemberAccountService', MemberAccountService)
        .factory('MemberService', MemberService);

    MemberAccountService.$inject = ['$resource'];

    function MemberAccountService($resource) {
        var service = $resource('api/member/:param', {}, {
            'register': {method: 'POST', params: {param: 'registry'}, isArray: false},
            'login': {method: 'POST', params: {param: 'authenticate'}, isArray: false},
            'update': {method: 'POST', params: {param: 'account/profile'}, isArray: false},
            'resetPassword': {method: 'POST', params: {param: 'account/resetPassword'}, isArray: false},
            'forgotPassword': {method: 'POST', params: {param: 'account/recoverPassword'}, isArray: false}
        });
        return service;
    }

    MemberService.$inject = ['$resource'];

    function MemberService($resource) {
        var service = $resource('api/members/:param', {}, {
            'check': {method: 'GET', params: {param: 'checkMember'}, isArray: false}
        });
        return service;
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ActivityController', ActivityController);
    ActivityController.$inject = ['$state', '$stateParams', '$scope', 'ActivityService', '$rootScope', 'MemberAccountService', 'Principal', 'MessageService', '$filter', 'AuthServerProvider'];

    function ActivityController($state, $stateParams, $scope, ActivityService, $rootScope, MemberAccountService, Principal, MessageService, $filter, AuthServerProvider) {
        /**
         * Created by vimpans on 2019/7/11
         */
        var vm = $scope;

        vm.activityModel = {};
        vm.recentlyList = [];
        vm.showApplyInfo = false;
        initDataAction();
        addListener();
        initRecentlyAction();
        function initDataAction() {
            ActivityService.findOne({id: $stateParams.id}, function (res) {
                vm.activityModel = res.data;
                vm.activityModel.insertHtml = '<br>'+
                    vm.activityModel.name+'<br>时间：'+
                    $filter('date')(vm.activityModel.startTime, 'yyyy-MM-dd HH:mm')+'-'+
                    $filter('date')(vm.activityModel.endTime, 'yyyy-MM-dd HH:mm')+'<br>地点：'+
                    vm.activityModel.addrProvince+
                    vm.activityModel.addrCity+
                    vm.activityModel.addrDistrict+
                    vm.activityModel.addrAddress;
                $('#activity-content').html(vm.activityModel.introduction);
            })
        }

        vm.currentPage = 0;
        vm.totalCount = null;
        vm.appliesList = [];
        initApplicantsData();
        function initApplicantsData() {
            ActivityService.appliers({page: vm.currentPage, size:10, activityId: $stateParams.id}, function (res) {
                vm.appliesList = vm.appliesList.concat(res.data);
                vm.totalCount = res.info.count;
            })
        }
        vm.loadMoreAction = function () {
            vm.currentPage++;
            initApplicantsData();
        };

        // vm.managerExtend = false;
        // vm.extendManagerAction = function () {
        //     vm.managerExtend = !vm.managerExtend;
        //     calculateAgency();
        // };
        function addListener() {
            window.onscroll = function (ev) {
                calculateAgency();
                vm.$apply();
            }
        }
        function calculateAgency() {
            var totalHeight = $('.agency-container')[0].clientHeight;
            var contentHeight = $('.agency-content')[0].clientHeight;
            var t = document.documentElement.scrollTop || document.body.scrollTop;
            vm.agencyContentTop = (t-284)>0 ? (t-284) : 0;
            if (contentHeight + vm.agencyContentTop > totalHeight) {
                vm.agencyContentTop = totalHeight-contentHeight-5;
            }
        }
        function initRecentlyAction() {
            ActivityService.findRecently({
                page: 0,
                size: 2
            }, function (res) {
                vm.recentlyList = res.data;
            });
        }

        vm.userModel = null;
        vm.isApply = false;
        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                initApplyInfo();
            });
        }

        function initApplyInfo() {
            ActivityService.applyInfo({id: $stateParams.id}, function (res) {
                if (res.data) {
                    vm.isApply = true;
                }
            })
        }

        vm.loginModel = {rememberMe:false};

        vm.loginAction = function () {
            MemberAccountService.login(vm.loginModel, function (res) {
                AuthServerProvider.storeAuthenticationToken(res.id_token, vm.loginModel.rememberMe);
                $rootScope.$broadcast('authenticationSuccess');
                window.location.reload();
                MessageService.success("登录成功 ！");
                initUserInfo();
            }, function (error) {
                MessageService.error("登录失败 ！"+(error.data.detail||''));
            });
        };

        vm.keyupAction = function (e) {
            if (e.keyCode === 13 && vm.loginModel.username && vm.loginModel.password) {
                vm.loginAction();
            }
        };

        vm.applyAction = function () {
            ActivityService.apply({activityId: vm.activityModel.id}, function (res) {
                vm.isApply = true;
                MessageService.contentKnow({
                    title: '报名成功',
                    content: vm.activityModel.insertHtml,
                    issue: '知道了'
                })
            }, function (error) {
                MessageService.contentKnow({
                    title: '报名失败',
                    content: error.data.detail,
                    issue: '知道了'
                })
            })
        };
        vm.cancelApplyAction = function () {
            MessageService.contentConfirm({
                title: '确定取消报名？',
                content: vm.activityModel.insertHtml,
                issue: '取消报名',
                cancel: '关闭'
            }, function () {
                //取消
                ActivityService.cancelApply({id: vm.activityModel.id}, function (res) {
                    vm.isApply = false;
                }, function (error) {
                    MessageService.contentKnow({
                        title: '取消失败',
                        content: error.data.detail,
                        issue: '知道了'
                    })
                });
            });
        };

        vm.requestRegisterAction = function () {
            $rootScope.$emit("requestRegister");
        }
    }
})();

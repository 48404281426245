(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberWifiController', MemberWifiController);
    MemberWifiController.$inject = ['$state', 'entity', '$scope', '$uibModalInstance', 'CompanyService'];

    function MemberWifiController($state, entity, $scope, $uibModalInstance, CompanyService) {
        /**
         * Created by vimpans on 2019/7/29
         */
        var vm = $scope;

        if (entity) {
            vm.entity = entity;
        }

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        initDataAction();
        vm.wifiModel = {};
        function initDataAction() {
            CompanyService.getWifi({officeId: vm.entity.id, companyId: vm.entity.companyId}, function (res) {
                vm.wifiModel = res.data;
            })
        }
    }
})();

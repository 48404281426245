(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('DefaultController', DefaultController);
    DefaultController.$inject = ['$state', '$scope'];

    function DefaultController($state, $scope) {
        /**
         * Created by vimpans on 2019/7/3
         */
        $state.go('home');
    }
})();

(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('ActivityService', ActivityService);

    ActivityService.$inject = ['$resource'];

    function ActivityService($resource) {
        var service = $resource('api/activity/:param', {}, {
            'findRecently': {method: 'GET', params: {param: 'findRecently'}, isArray: false},
            'findHistory': {method: 'GET', params: {param: 'findHistory'}, isArray: false},
            'appliers': {method: 'GET', params: {param: 'getHideApplicants'}, isArray: false},
            'applyInfo': {method: 'GET', params: {param: 'getMyApplicant'}, isArray: false},
            'apply': {method: 'POST', params: {param: 'apply'}, isArray: false},
            'cancelApply': {method: 'POST', params: {param: 'cancelMyApplicant'}, isArray: false},
            'findOne': {method: 'GET', params: {param: 'findOne'}, isArray: false}
        });
        return service;
    }
})();

(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('BookItemService', BookItemService);

    BookItemService.$inject = ['$resource'];

    function BookItemService($resource) {
        var service = $resource('api/bookItem/:param', {}, {
            'queryCalenderItems': {method: 'GET', params: {param: 'queryCalenderItems'}, isArray: false}
        });
        return service;
    }
})();

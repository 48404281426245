(function() {
    'use strict';

    angular
        .module('gwApp')
        .factory('validateInput', validateInput);

    function validateInput() {
        var mathod = {};

        //印厂缩写
        mathod.isIdentifier = function (identifier) {
            return identifier.match("^[A-Za-z]+[A-Za-z0-9]*$") === null;
        };

        //用户名称
        mathod.isUserName = function (name) {
            return name.match("^[\u4e00-\u9fa5_'.@A-Za-z0-9-]{8,20}$") === null;
        };

        //密码
        mathod.isPassword = function (password) {
            return password.match("^[_*'.@A-Za-z0-9-]{8,32}$") === null;
        };

        //邮箱
        mathod.isEmail = function (email) {
            return email.match("^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$") === null;
        };

        //手机号码
        mathod.isPhone = function (phone) {
            return phone.match("^1[3|4|5|7|8][0-9]{9}$") === null;
        };

        return mathod;
    }
})();

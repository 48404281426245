(function () {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$rootScopeProvider'];

    /**
     * Created by vimpans on 2019/7/3
     */

    function stateConfig($stateProvider) {
        $stateProvider
            .state('default', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: []
                },
                views: {
                    'index@': {
                        templateUrl: 'app/default/default.html',
                        controller: 'DefaultController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
    }
})();

(function() {
    'use strict';

    angular
        .module('gwApp')
        .directive('validate', validate);

    function validate () {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs, ngModel) {
            ngModel.$validators.validate = function(modelValue,viewValue){
                if(modelValue!=null&&modelValue!=''){
                    if(attrs.pattern!=null&&attrs.pattern!=''){
                        if(modelValue.match(attrs.pattern)!=null){
                            ngModel.$error = {};
                            ngModel.$invalid = false;
                            ngModel.$dirty = false;
                            return ngModel.$error.pattern = false;
                        }else{
                            ngModel.$error = {};
                            ngModel.$invalid = true;
                            ngModel.$dirty = true;
                            return ngModel.$error.pattern = true;
                        }
                    }else{
                        if(attrs.name=="name"){
                            if(modelValue.match("^[\u4e00-\u9fa5_'.A-Za-z0-9-]{4,20}$")!=null){
                                ngModel.$error = {};
                                ngModel.$invalid = false;
                                ngModel.$dirty = false;
                                return ngModel.$error.pattern = false;
                            }else{
                                ngModel.$error = {};
                                ngModel.$invalid = true;
                                ngModel.$dirty = true;
                                return ngModel.$error.pattern = true;
                            }
                        }else{
                            ngModel.$error = {};
                            ngModel.$invalid = true;
                            ngModel.$dirty = true;
                            return ngModel.$error.pattern = true;
                        }
                    }
                }else{
                    if(JSON.stringify(ngModel.$error)=="{}"){
                        ngModel.$error = {};
                        ngModel.$invalid = false;
                        ngModel.$dirty = false;
                        return ngModel.$error.require = true;
                    }else{
                        ngModel.$error = {};
                        ngModel.$invalid = true;
                        ngModel.$dirty = true;
                        return ngModel.$error.require = true;
                    }
                }
            }
        }
    }
})();

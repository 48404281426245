(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberAppointmentConfirmController', MemberAppointmentConfirmController);
    MemberAppointmentConfirmController.$inject = ['$state', '$scope'];

    function MemberAppointmentConfirmController($state, $scope) {
        /**
         * Created by vimpans on 2019/8/12
         */

    }
})();

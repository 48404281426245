(function() {
    'use strict';
    angular
        .module('gwApp')
        .directive('inputSelect', inputSelect);

    function inputSelect() {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                //element.bind('mouseover',function(event){// 鼠标滑到数据时，选中
                //        this.select();
                //});
                // element.bind('click', function (event) {// 点击时，选中
                //     this.select();
                // });
                element.bind('focus', function (event) {// 获取焦点时，选中
                    this.select();
                });
            }
        };
    }
})();

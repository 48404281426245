(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('BuildingController', BuildingController);
    BuildingController.$inject = ['$state', '$scope', '$stateParams', 'BuildingService', 'RoomService', 'RegionManagerService', '$localStorage'];

    function BuildingController($state, $scope, $stateParams, BuildingService, RoomService, RegionManagerService, $localStorage) {
        /**
         * Created by vimpans on 2019/7/3
         */
        var vm = $scope;

        vm.locationId = $localStorage.location_id;
        vm.locationName = $localStorage.location_name;

        vm.extendMore = false;

        vm.buildingList = [];
        vm.holdQuantityStatus = false;
        vm.regionSelStatus = false;
        vm.priceSelStatus = false;
        vm.regionList = [];
        vm.selectedRegion = null;
        vm.tempRegion = null;
        vm.holdQuantity = null;
        vm.tempQuantity = null;
        vm.priceIndex = -1;
        vm.priceRegionIndex = -1;
        vm.roomType = null;
        vm.priceSort = false;

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };

        if ($stateParams.keyword) {
            vm.pageparams.keyword = $stateParams.keyword;
        }

        vm.searchAction = function () {
            vm.pageparams.page = 1;
            vm.selectedRegion = null;
            vm.tempRegion = null;
            vm.holdQuantity = null;
            vm.tempQuantity = null;
            vm.priceIndex = -1;
            vm.priceRegionIndex = -1;
            vm.roomType = null;
            initDataAction();
        };
        vm.keyupAction = function (e) {
            if (e.keyCode == 13) {
                vm.searchAction();
            }
        };

        function initCityAction() {
            RegionManagerService.findByCity({cId: vm.locationId}, function (res) {
                vm.regionList = group(res.data, 5);
            })
        }

        function initDataAction() {
            BuildingService.query({keyword: vm.keyword, priceSort: vm.priceSort, page: vm.pageparams.page-1, size: vm.pageparams.size}, function (res) {
                vm.buildingList = res.data;
                vm.pageparams.count = res.info.count;
            });
        }

        vm.searchAction();
        initCityAction();

        vm.initByRoom = function () {
            vm.pageparams.keyword = null;
            vm.pageparams.page = 1;
            initByRoomAction();
        };

        vm.conditionSearchMode = false;

        vm.pageChangeAction = function () {
            if (vm.conditionSearchMode) {
                initByRoomAction()
            } else {
                initDataAction()
            }
        };

        function initByRoomAction() {
            vm.conditionSearchMode = true;
            var params = {priceSort: vm.priceSort, city: vm.locationName};
            if (vm.selectedRegion) {params.region = vm.selectedRegion.name}
            if (vm.holdQuantity) {params.sCapacity = vm.holdQuantity;params.eCapacity = 100000000}
            if (vm.roomType) {params.type = vm.roomType}
            if (vm.priceIndex !== -1) {
                switch (vm.priceIndex) {
                    case 0: params.sPrice = 1000;params.ePrice = 2000; break;
                    case 1: params.sPrice = 2000;params.ePrice = 4000; break;
                    case 2: params.sPrice = 4000;params.ePrice = 100000000000; break;
                }
            }
            params.page = vm.pageparams.page-1;
            params.size = vm.pageparams.size;
            BuildingService.search(params, function (res) {
                vm.buildingList = res.data;
                vm.pageparams.count = res.info.count;
            })
        }

        function group(array, subGroupLength) {
            var groupIndex = 0;
            var newArray = [];
            while(groupIndex < array.length) {
                newArray.push(array.slice(groupIndex, groupIndex += subGroupLength));
            }
            return newArray;
        }

        vm.radioClick = function (index) {
            if (vm.priceRegionIndex === index) {
                vm.priceRegionIndex = -1;
            } else {
                vm.priceRegionIndex = index;
            }
        };
        vm.getRadioContent = function (index) {
            if (index === -1) {
                return "价格区间";
            } else if (index === 0) {
                return "1000-2000￥/月";
            } else if (index === 1) {
                return "2000-4000￥/月";
            } else if (index === 2) {
                return ">4000￥/月";
            }
        };

        vm.selectRegion = function (a,b) {
            vm.tempRegion = vm.regionList[a][b];
        };
        vm.cancelPopover = function (index) {
            switch (index) {
                case 0: vm.holdQuantityStatus=false; break;
                case 1: vm.regionSelStatus = false; break;
                case 2: vm.priceSelStatus = false; break;
            }
        };
        vm.issuePopover = function (index) {
            switch (index) {
                case 0: vm.holdQuantity=vm.tempQuantity;vm.holdQuantityStatus=false; break;
                case 1: vm.selectedRegion=vm.tempRegion;vm.regionSelStatus = false; break;
                case 2: vm.priceIndex=vm.priceRegionIndex;vm.priceSelStatus = false; break;
            }
            initByRoomAction();
        };
        vm.selRoomType = function (type) {
            vm.roomType = type;
            initByRoomAction();
        };
        vm.changeQuantity = function (plus) {
            if (plus) {
                if (!vm.tempQuantity) {vm.tempQuantity=0}
                vm.tempQuantity++;
            } else {
                if (vm.tempQuantity&&vm.tempQuantity!==1) {
                    vm.tempQuantity--;
                }
            }
        };
        vm.clearTempRegion = function () {
            vm.tempRegion=null
        };
        vm.changeSort = function (sort) {
            vm.priceSort = sort;
            initByRoomAction();
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('BuildingService', BuildingService);

    BuildingService.$inject = ['$resource'];

    function BuildingService($resource) {
        var service = $resource('api/building/:param', {}, {
            'query': {method: 'GET', params: {param: 'findByKeyWord'}, isArray: false},
            'search': {method: 'GET', params: {param: 'search'}, isArray: false},
            'findOne': {method: 'GET', params: {param: 'findOne'}, isArray: false},
            'getOne': {method: 'GET', params: {param: 'getOne'}, isArray: false},
            'getLevel': {method: 'GET', params: {param: 'getLevel'}, isArray: false}
        });
        return service;
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('PreviewController', PreviewController);
    PreviewController.$inject = ['$state', '$scope', 'HomeConfigService'];

    function PreviewController($state, $scope, HomeConfigService) {
        /**
         * Created by vimpans on 2019/8/20
         */
        var vm = $scope;

        vm.myInterval = 5000;
        vm.noWrapSlides = false;
        vm.active = 0;
        vm.keyword = null;

        // var $API = null;
        // vm.playerReady = function (a) {
        //     $API = a;
        // };
        // vm.playVideo = function () {
        //     $API.play();
        // };

        vm.homeModel = {};
        vm.bannerModel = {};
        vm.buildingModel = null;
        vm.buildingList = [];
        vm.advertiseModel = {};
        vm.activityList = [];
        vm.videoModel = null;
        initDataAction();
        function initDataAction() {
            HomeConfigService.findSave({}, function (res) {
                vm.homeModel = res.data;
                if (!vm.homeModel) {return}
                for (var i = 0; i<vm.homeModel.resources.length; i++) {
                    var resource = vm.homeModel.resources[i];
                    if (resource.type === "BANNER") {
                        if (vm.homeModel.type === 'SLIDE') {
                            vm.bannerModel = resource;
                        } else if (vm.homeModel.type === 'VIDEO') {
                            vm.videoModel = resource.category.images[0]||{};
                        }
                    } else if (resource.type === "ADVERTISE") {
                        vm.advertiseModel = resource;
                    } else if (resource.type === "BUILDING") {
                        if (!vm.buildingModel) {
                            vm.buildingModel = resource;
                        } else {
                            vm.buildingList.push(resource);
                        }
                    } else if (resource.type === "ACTIVITY") {
                        vm.activityList.push(resource);
                    }
                }
            });
        }
        vm.translateLevel = function (level) {
            if (level === 'ORGANIZATION') {
                return "租户会员";
            }
            if (level === 'PLATFOMEUSER') {
                return "平台以上会员";
            }
            if (level === 'OPEN') {
                return "完全开放";
            }
        };

        // vm.appointmentAction = function () {
        //     $uibModal.open({
        //         animation: true,
        //         keyboard: false,
        //         backdrop: 'static',
        //         templateUrl: 'app/module/appointment/appointment.html',
        //         controller: 'AppointmentController',
        //         controllerAs: 'vm',
        //         size: 'md',
        //         resolve: {
        //             entity: function () {
        //                 return null;
        //             }
        //         }
        //     }).result.then(function () {
        //
        //     });
        // };

        // vm.searchAction = function () {
        //     if (!vm.keyword) {return;}
        //     $state.go('building', {keyword: vm.keyword});
        // };
        // vm.keyupAction = function (e) {
        //     if (e.keyCode === 13) {
        //         vm.searchAction();
        //     }
        // }
    }
})();

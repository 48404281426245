(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('BuildingDetailController', BuildingDetailController);
    BuildingDetailController.$inject = ['$state', '$stateParams', '$scope', 'BuildingService', 'RoomService', '$interval', '$uibModal'];

    function BuildingDetailController($state, $stateParams, $scope, BuildingService, RoomService, $interval, $uibModal) {
        /**
         * Created by vimpans on 2019/7/4
         */

        var vm = $scope;

        vm.buildingModel = null;
        vm.map = null;
        vm.centerPoint = null;
        vm.local = null;
        vm.agencyContentTop = 0;
        vm.roomList = [];
        vm.roomPage = 0;
        vm.extendArea = false;

        vm.holdQuantityStatus = false;
        vm.holdQuantity = null;
        vm.tempQuantity = null;

        vm.priceSelStatus = false;
        vm.priceIndex = -1;
        vm.priceRegionIndex = -1;

        vm.typeSelStatus = false;
        vm.typeValueList = ["OFFICE","CONFERENCE","MEETING","SHOW","INDEPENDENT"];
        vm.typeNameList = ["办公室","会议室","洽谈室","路演室","独立办公区域"];
        vm.typeIndex = -1;
        vm.typeRegionIndex = -1;

        vm.statusSelStatus = false;
        vm.statusValueList = ["NOTRENT","SHARED","RENTED"];
        vm.statusNameList = ["未租","共享","已租"];
        vm.statusIndex = -1;
        vm.statusRegionIndex = -1;

        vm.floorSelStatus = false;
        vm.floorList = [];
        vm.selectedFloor = null;
        vm.tempFloor = null;

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };

        initBuildingAction();
        addListener();
        function initBuildingAction() {
            BuildingService.findOne({id: $stateParams.id}, function (res) {
                vm.buildingModel = res.data;
                vm.buildingModel.address = (vm.buildingModel.addrProvince||'')+(vm.buildingModel.addrCity||'')+(vm.buildingModel.addrDistrict||'')+(vm.buildingModel.addrAddress||'');
                initMapAction();
                initRoomAction();
                autoSwiper();
                roomAutoSwiper();
            });
            BuildingService.getLevel({bId: $stateParams.id}, function (res) {
                vm.floorList = group(res.data, 5);
            })
        }

        vm.pageChangeAction = function () {
            // initDataAction();
            initRoomAction();
        };

        function initRoomAction() {
            var params = {page:vm.roomPage, size: 10, bId: vm.buildingModel.id};
            if (vm.holdQuantity) {params.sCapacity = vm.holdQuantity;params.eCapacity = 100000000}
            if (vm.roomType) {params.type = vm.roomType}
            if (vm.priceIndex !== -1) {
                switch (vm.priceIndex) {
                    case 0: params.sPrice = 1000;params.ePrice = 2000; break;
                    case 1: params.sPrice = 2000;params.ePrice = 4000; break;
                    case 2: params.sPrice = 4000;params.ePrice = 100000000000; break;
                }
            }
            if (vm.selectedFloor) {params.level = vm.selectedFloor}
            if (vm.typeIndex!==-1) {params.type = vm.typeValueList[vm.typeIndex]}
            if (vm.statusIndex!==-1) {params.state = vm.statusValueList[vm.statusIndex]}
            params.page = vm.pageparams.page-1;
            params.size = vm.pageparams.size;
            RoomService.filtration(params, function (res) {
                vm.roomList = res.data;
                vm.pageparams.count = res.info.count;
            })
        }

        function group(array, subGroupLength) {
            var groupIndex = 0;
            var newArray = [];
            while(groupIndex < array.length) {
                newArray.push(array.slice(groupIndex, groupIndex += subGroupLength));
            }
            return newArray;
        }

        function initMapAction(){
            if (!BMap) {return}
            vm.map = new BMap.Map("baiduMap");
            vm.map.addControl(new BMap.NavigationControl({type: BMAP_NAVIGATION_CONTROL_ZOOM} ));
            vm.map.enableScrollWheelZoom();
            vm.map.enableKeyboard();
            vm.map.enableDragging();
            vm.map.enableDoubleClickZoom();
            vm.local = new BMap.LocalSearch(vm.map, {renderOptions: {map: vm.map, autoViewport: false}});
            // addMapOverlay();
            addressToPoint(function (point) {
                vm.centerPoint = point;
                vm.map.centerAndZoom(point, 17);
                vm.map.addOverlay(new BMap.Marker(point));
            });
        }

        function addressToPoint(cb) {
            var geo = new BMap.Geocoder();
            geo.getPoint(vm.buildingModel.address, cb, vm.buildingModel.addrProvince);
        }
        vm.nearbySearch = function (keys) {
            if (!keys) {return}
            vm.local.clearResults();
            vm.local.searchNearby(keys,vm.centerPoint,1000);
        };

        function addMapOverlay() {
            var icon = new BMap.Icon('../image/aboutus-map-annotation.png', new BMap.Size(50, 45), {
                anchor: new BMap.Size(15, 50)
            });
            var mkr = new BMap.Marker(new BMap.Point(121.456618,31.232689), {
                icon: icon
            });
            vm.map.addOverlay(mkr);
        }

        vm.managerExtend = false;
        vm.extendManagerAction = function () {
            vm.managerExtend = !vm.managerExtend;
            calculateAgency();
        };
        function addListener() {
            window.onscroll = function (ev) {
                calculateAgency();
                vm.$apply();
            }
        }
        function calculateAgency() {
            var totalHeight = $('.agency-container')[0].clientHeight;
            var contentHeight = $('.agency-content')[0].clientHeight;
            var t = document.documentElement.scrollTop || document.body.scrollTop;
            vm.agencyContentTop = (t-284)>0 ? (t-284) : 0;
            if (contentHeight + vm.agencyContentTop > totalHeight) {
                vm.agencyContentTop = totalHeight-contentHeight-5;
            }
        }

        vm.cancelPopover = function (index) {
            switch (index) {
                case 0: vm.holdQuantityStatus=false; break;
                case 1: vm.floorSelStatus = false; break;
                case 2: vm.priceSelStatus = false; break;
                case 3: vm.typeSelStatus = false; break;
                case 4: vm.statusSelStatus = false; break;
            }
        };
        vm.issuePopover = function (index) {
            switch (index) {
                case 0: vm.holdQuantity=vm.tempQuantity;vm.holdQuantityStatus=false; break;
                case 1: vm.selectedFloor=vm.tempFloor;vm.floorSelStatus = false; break;
                case 2: vm.priceIndex=vm.priceRegionIndex;vm.priceSelStatus = false; break;
                case 3: vm.typeIndex=vm.typeRegionIndex;vm.typeSelStatus = false; break;
                case 4: vm.statusIndex=vm.statusRegionIndex;vm.statusSelStatus = false; break;
            }
            initRoomAction();
        };
        vm.radioClick = function (index) {
            if (vm.priceRegionIndex === index) {
                vm.priceRegionIndex = -1;
            } else {
                vm.priceRegionIndex = index;
            }
        };
        vm.getRadioContent = function (index) {
            if (index === -1) {
                return "价格区间";
            } else if (index === 0) {
                return "1000-2000￥/月";
            } else if (index === 1) {
                return "2000-4000￥/月";
            } else if (index === 2) {
                return ">4000￥/月";
            }
        };
        vm.radioTypeClick = function (index) {
            if (vm.typeRegionIndex === index) {
                vm.typeRegionIndex = -1;
            } else {
                vm.typeRegionIndex = index;
            }
        };
        vm.radioStatusClick = function (index) {
            if (vm.statusRegionIndex === index) {
                vm.statusRegionIndex = -1;
            } else {
                vm.statusRegionIndex = index;
            }
        };
        vm.changeQuantity = function (plus) {
            if (plus) {
                if (!vm.tempQuantity) {vm.tempQuantity=0}
                vm.tempQuantity++;
            } else {
                if (vm.tempQuantity&&vm.tempQuantity!==1) {
                    vm.tempQuantity--;
                }
            }
        };
        vm.selectFloor = function (a,b) {
            vm.tempFloor = vm.floorList[a][b];
        };
        vm.tonull = function (a) {vm[a] = null; initRoomAction()};
        vm.to_1 = function (a) {vm[a] = -1; initRoomAction()};

        vm.swiper_animation = { transform: 'translateX(0)' };
        vm.swiper_countor = 0;
        function autoSwiper() {
            if (!vm.buildingModel.previewImages.images || vm.buildingModel.previewImages.images.length===0 || vm.buildingModel.previewImages.images.length===1) {
                return;
            }
            vm.interval = $interval(function () {
                vm.swiper_countor++;
                if (vm.swiper_countor === vm.buildingModel.previewImages.images.length) {
                    vm.swiper_countor = 0;
                }
                vm.swiper_animation.transform = 'translateX('+(-vm.swiper_countor * 830)+'px)';
            }, 5000);
        }
        vm.tapSwiperBtn = function (idex) {
            if (!vm.buildingModel.previewImages.images || vm.buildingModel.previewImages.images.length===0 || vm.buildingModel.previewImages.images.length===1) {
                return;
            }
            $interval.cancel(vm.interval);
            vm.swiper_animation.transform = 'translateX('+(-idex * 830)+'px)';
            vm.swiper_countor = idex;
            autoSwiper();
        };
        vm.previousImageAction = function () {
            vm.swiper_countor--;
            vm.tapSwiperBtn(vm.swiper_countor);
        };
        vm.nextImageAction = function () {
            vm.swiper_countor++;
            vm.tapSwiperBtn(vm.swiper_countor);
        };

        vm.rooms_animation = {transform: 'translateX(0)'};
        vm.rooms_countor = 0;
        function roomAutoSwiper() {
            if (!vm.buildingModel.roomImages.images || vm.buildingModel.roomImages.images.length===0 || vm.buildingModel.roomImages.images.length===1) {
                return;
            }
            vm.roomTnterval = $interval(function () {
                vm.rooms_countor++;
                if (vm.rooms_countor === vm.buildingModel.roomImages.images.length) {
                    vm.rooms_countor = 0;
                }
                vm.rooms_animation.transform = 'translateX('+(-vm.rooms_countor * 830)+'px)';
            }, 5000);
        }
        vm.tapRoomSwiperBtn = function (idex) {
            if (!vm.buildingModel.roomImages.images || vm.buildingModel.roomImages.images.length===0 || vm.buildingModel.roomImages.images.length===1) {
                return;
            }
            $interval.cancel(vm.roomTnterval);
            vm.rooms_animation.transform = 'translateX('+(-idex * 830)+'px)';
            vm.rooms_countor = idex;
            roomAutoSwiper();
        };
        vm.previousRoomImageAction = function () {
            vm.rooms_countor--;
            vm.tapRoomSwiperBtn(vm.rooms_countor);
        };
        vm.nextRoomImageAction = function () {
            vm.rooms_countor++;
            vm.tapRoomSwiperBtn(vm.rooms_countor);
        };

        vm.appointmentAction = function (roomName) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/appointment/appointment.html',
                controller: 'AppointmentController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {source: vm.buildingModel.name+(roomName?('/'+roomName):''), buildingId: vm.buildingModel.id, managerName: vm.buildingModel.managerName, managerPhone: vm.buildingModel.managerPhone};
                    }
                }
            }).result.then(function () {

            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('module', {
                parent: 'app',
                url: '/module',
                data: {
                    authorities: []
                },
                views: {
                    'index@': {
                        templateUrl: 'app/module/module.html',
                        controller: 'ModuleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('register', {
                parent: 'module',
                url: '/register',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/register/register.html',
                        controller: 'RegisterController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('login', {
                parent: 'module',
                url: '/login',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/login/login.html',
                        controller: 'LoginController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('home', {
                parent: 'module',
                url: '/home',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/home/home.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('memberCenter', {
                parent: 'module',
                url: '/mcenter',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/member-center/member-center.html',
                        controller: 'MemberCenterController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberInfoController', MemberInfoController);
    MemberInfoController.$inject = ['$state', 'entity', '$scope', '$uibModalInstance', 'Principal', 'Funcs', 'MemberAccountService', 'MessageService', '$uibModal'];

    function MemberInfoController($state, entity, $scope, $uibModalInstance, Principal, Funcs, MemberAccountService, MessageService, $uibModal) {
        /**
         * Created by vimpans on 2019/7/29
         */
        var vm = $scope;

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        if (entity) {
            vm.accessCardId = entity.accessCardId;
            vm.companyName = entity.companyName;
            vm.roomName = entity.roomName;
        }

        vm.userModel = null;
        vm.userImageModel = null;

        userAccountInfo();
        function userAccountInfo(force) {
            Principal.identity(force).then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                vm.userImageModel = angular.copy(account.data);
            })
        }

        vm.updateProfile = function () {
            MemberAccountService.update(vm.userModel, function () {
                userAccountInfo(true);
                MessageService.success('用户信息修改成功 !');
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error('用户信息修改失败 '+(error.data.detail || ""));
            })
        };

        vm.headerUpload = function () {
            Funcs.imageUpload(function (res) {
                vm.userImageModel.imageUrl = res.imageUrl;
                MemberAccountService.update(vm.userImageModel, function () {
                    userAccountInfo(true);
                    MessageService.success('头像上传成功 !');
                }, function (error) {
                    MessageService.error('头像上传失败 '+(error.data.detail || ""));
                })
            })
        };

        vm.resetPassword = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/module/member-center/member-reset-password.html',
                controller: 'MemberResetPasswordController',
                controllerAs: 'vm',
                size: 'normal',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {

            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberAppointmentController', MemberAppointmentController);
    MemberAppointmentController.$inject = ['$state', 'entity', '$scope', '$uibModalInstance', '$timeout', 'CityManagerService', 'RegionManagerService', 'BuildingManagerService', 'RoomManagerService', 'OfficeService', 'MessageService', '$filter', 'OfficeItemService', '$compile'];

    function MemberAppointmentController($state, entity, $scope, $uibModalInstance, $timeout, CityManagerService, RegionManagerService, BuildingManagerService, RoomManagerService, OfficeService, MessageService, $filter, OfficeItemService, $compile) {
        /**
         * Created by vimpans on 2019/7/17
         */
        var vm = $scope;

        vm.companyId = null;
        vm.office = null;
        if (entity) {
            vm.companyId = entity.companyId;
            vm.buildingIds = entity.buildingIds||[];
            vm.office = entity.office;
        }

        vm.notMatch = false;
        vm.showDescription = false;
        vm.calendarStart = null;
        vm.calendarEnd = null;
        vm.calendar = null;
        vm._timeout = null;

        vm.uiConfig = {
            calendar: {
                height: 'parent',
                editable: false,
                header: {
                    left: '',
                    center: '',
                    right: 'month agendaWeek agendaDay'
                },
                buttonText: {
                    month: '月',
                    agendaWeek: '周',
                    agendaDay: '日'
                },
                firstDay: 1,
                slotLabelFormat: "HH:mm",
                columnFormat: 'ddd',
                timeFormat: "HH:mm",
                allDayText: "全天",
                dayNamesShort: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                eventRender: function (event, element, view) {
                    element.attr({
                        'uib-tooltip-html': "'<b style=\"text-align: left\">"+ event.title + " <br>开始时间: " + $filter('date')(new Date(event.start._d), 'yyyy-MM-dd HH:mm') + " <br>结束时间: " + $filter('date')(new Date(event.end._d), 'yyyy-MM-dd HH:mm') + "</b>'"
                    });

                    $compile(element)($scope);
                },
                viewRender: function(view, element) {
                    if (!vm.calendar) {
                        vm.calendar = view.calendar;
                    }
                    vm.calendarStart = view.start._d.toStartISOString();
                    vm.calendarEnd = view.end._d.toEndISOString();
                    if (vm.bsModel.meeting.id) {

                        if(vm._timeout) {
                            $timeout.cancel(vm._timeout);
                        }
                        vm._timeout = $timeout(function () {
                            initMeetingDataAction();
                            vm._timeout = null;
                        }, 1000);
                    }
                    updateTitle(view);
                }
            }
        };

        vm.titleDate = '';
        function updateTitle(view) {
            switch (view.type) {
                case 'month':
                    vm.titleDate = $filter('date')(new Date(view.intervalStart._d), 'yyyy年MM月');
                    break;
                case 'agendaWeek':
                    vm.titleDate = $filter('date')(new Date(view.start._d), 'yyyy年MM月') +'第'+ getMonthWeek(view.start._d) +'周';
                    break;
                case 'agendaDay':
                    vm.titleDate = $filter('date')(new Date(view.start._d), 'yyyy年MM月dd日');
                    break;
                default:
                    break;
            }
        }
        vm.next = function () {
            if (vm.calendar) {
                vm.calendar.next();
            }
        };
        vm.prev = function () {
            if (vm.calendar) {
                vm.calendar.prev();
            }
        };

        function getMonthWeek(s) {
            const date = new Date(s.getFullYear(), s.getMonth() - 1, s.getDate());
            const w = date.getDay();
            const d = date.getDate();
            return Math.ceil((d + 6 - w) / 7);
        }

        vm.events = [];
        vm.eventSources = [vm.events];

        vm.cancelAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.cityList = [];
        vm.regionList = [];
        vm.buildingList = [];
        vm.meetingList = [];
        vm.startTimeSel = false;
        vm.endTimeSel = false;
        vm.dateSel = false;
        vm.bsModel = {
            city: {id: vm.office.cityId, name: vm.office.cityName},
            region: {id: vm.office.regionId, name: vm.office.regionName},
            building: {id: vm.office.buildingId, name: vm.office.buildingName},
            meeting: {}
        };
        vm.appointmentModel = {cycleName:'月', cycleType:'MONTH', companyId:vm.companyId};

        initCityAction();
        if (vm.bsModel.city.id) {
            initRegionByCity(vm.bsModel.city.id)
        }
        if (vm.bsModel.city.id && vm.bsModel.region.id) {
            initBuildingByCityAndRegion(vm.bsModel.city.id, vm.bsModel.region.id)
        }
        if (vm.bsModel.building.id) {
            initRoomsByBuilding(vm.bsModel.building.id)
        }
        function initCityAction() {
            CityManagerService.findAll({}, function (res) {
                vm.cityList = res.data;
            });
        }
        function initRegionByCity(city) {
            RegionManagerService.findByCity({cId: city}, function (res) {
                vm.regionList = res.data;
            })
        }
        function initBuildingByCityAndRegion(city, region) {
            BuildingManagerService.findByArea({cityId: city, regionId: region}, function (res) {
                vm.buildingList = res.data;
            })
        }
        function initRoomsByBuilding(building) {
            RoomManagerService.findConference({bId: building, available: true}, function (res) {
                vm.meetingList = res.data.map(function (val) {
                    val.name = (val.title+"("+val.name+")");
                    return val;
                });
            })
        }

        vm.sCity = function (item) {
            if (vm.bsModel.city.id === item.id) {return;}
            vm.bsModel.city.id = item.id;
            vm.bsModel.city.name = item.name;
            dataCleanerBy_meeting_building_region(true, true, true);
            initRegionByCity(item.id);
        };
        vm.sRegion = function (item) {
            if (vm.bsModel.region.id === item.id) {return;}
            vm.bsModel.region.id = item.id;
            vm.bsModel.region.name = item.name;
            dataCleanerBy_meeting_building_region(true, true);
            initBuildingByCityAndRegion(vm.bsModel.city.id, item.id);
        };
        vm.sBuilding = function (item) {
            if (vm.bsModel.building.id === item.id) {return;}
            vm.bsModel.building.id = item.id;
            vm.bsModel.building.name = item.name;

            // vm.notMatch = vm.bsModel.building.id !== vm.office.buildingId;
            vm.notMatch = vm.buildingIds.indexOf(vm.bsModel.building.id)===-1;
            dataCleanerBy_meeting_building_region(true);
            initRoomsByBuilding(item.id);
        };
        vm.sMeeting = function (item) {
            if (vm.bsModel.meeting.id === item.id) {return;}
            vm.bsModel.meeting = item;
            initMeetingDataAction();
        };

        function dataCleanerBy_meeting_building_region(meeting, building, region) {
            if (meeting) {vm.bsModel.meeting = {}; vm.meetingList = []}
            if (building) {vm.bsModel.building = {}; vm.buildingList = []}
            if (region) {vm.bsModel.region = {}; vm.regionList = []}
        }

        function initMeetingDataAction() {
            if (!vm.calendarStart || !vm.calendarEnd) {return}
            OfficeItemService.queryCalenderItems({
                rId: vm.bsModel.meeting.id,
                startTime: vm.calendarStart,
                endTime: vm.calendarEnd
            }, function (res) {
                var s = vm.events.length - 1;
                while (s >= 0) {
                    vm.events.splice(s--, 1);
                }
                for (var i=0; i<res.data.length; i++) {
                    var result = res.data[i];
                    vm.events.push({
                        title: vm.companyId===result.companyId?result.subject:'',
                        start: new Date(result.startTime),
                        end: new Date(result.endTime)
                    });
                }
            })
        }

        vm.changeQuantity = function (plus) {
            if (!vm.appointmentModel.numberOfParticipants) {
                vm.appointmentModel.numberOfParticipants = 0;
            }
            if (plus) {
                vm.appointmentModel.numberOfParticipants ++;
            } else {
                vm.appointmentModel.numberOfParticipants --;
                vm.appointmentModel.numberOfParticipants = vm.appointmentModel.numberOfParticipants < 0 ? 0 : vm.appointmentModel.numberOfParticipants;
            }
        };

        vm.createBookJobAction = function () {
            if (!vm.bsModel.meeting.id) {
                MessageService.error("请先选择会议室 !");
                return;
            }
            vm.appointmentModel.roomId = vm.bsModel.meeting.id;
            vm.appointmentModel.date = $filter('date')(vm.appointmentModel.dateShow, 'yyyy-MM-dd');
            vm.appointmentModel.endDate = $filter('date')(vm.appointmentModel.endDateShow, 'yyyy-MM-dd');
            vm.appointmentModel.endTime = vm.appointmentModel.endTimeShow.split(' ')[0];

            var duration = calculateTime(vm.appointmentModel.date, vm.appointmentModel.startTime, vm.appointmentModel.endTimeShow);
            // var price = vm.bsModel.meeting.price;
            // if (vm.bsModel.meeting.promotionEnabled) {
            //     price = vm.bsModel.meeting.promotionPrice;
            // }
            // if (vm.notMatch) {
            //     price += price*0.3;
            // }

            initAmountAction(function (res) {
                var tempContent = '预定时间：'+vm.appointmentModel.date+' '+vm.appointmentModel.startTime+'-'+vm.appointmentModel.endTime+' 共'+duration+'小时';
                if (vm.appointmentModel.repeat) {
                    tempContent += ('<br>每'+vm.appointmentModel.cycleName+'重复 截止日期 '+vm.appointmentModel.endDate);
                }
                tempContent += ('<br><br><span style="font-size: 20px">共计：<span style="color: red">￥<span style="font-size: 42px">'+res.data.toFixed(2)+'</span></span></span>');
                MessageService.contentConfirm({
                    title: vm.bsModel.meeting.name+'<br><span style="font-size: 20px">'+vm.bsModel.city.name+'-'+vm.bsModel.region.name+'-'+vm.bsModel.building.name+'</span>',
                    content: tempContent,
                    issue: '预定',
                    desc: '预定需多付一小时费用作为押金，结算时多退少补。'
                }, function () {
                    OfficeService.create(vm.appointmentModel, function (res) {
                        MessageService.success("预约会议室成功 ！");
                        $uibModalInstance.close();
                    }, function (error) {
                        MessageService.error("预约会议室失败 "+(error.data.detail?(','+error.data.detail):''));
                    })
                })
            });

        };

        function initAmountAction(cb) {
            OfficeService.getPrice(vm.appointmentModel, function (res) {
                cb(res);
            }, function (error) {
                MessageService.error("预约会议室失败 "+(error.data.detail?(','+error.data.detail):''));
            })
        }

        function calculateTime(dateString, startTimeString, endTimeString) {
            if (!dateString || !startTimeString || !endTimeString) {return 0}
            var startTime = new Date(dateString+'T'+startTimeString);
            var endTimeArray = endTimeString.split(' ');
            var endTime = new Date(dateString+'T'+endTimeArray[0]);
            if (endTimeArray.length > 1) {
                endTime.setDate(endTime.getDate()+1);
            }
            return (endTime-startTime)/(3600000);
        }

        //================================start time selector================================
        vm.now = new Date();
        vm.timeEnumList = [];
        vm.initStartTimeEnum = initStartTimeEnum;
        vm.initEndTimeEnum = initStartTimeEnum;
        vm.appointmentModel.dateShow = new Date();
        initTimeEnum();
        function initTimeEnum() {
            for (var i=0; i<24; i++) {
                vm.timeEnumList.push(i<10?'0'+i+':00':i+':00');
                vm.timeEnumList.push(i<10?'0'+i+':30':i+':30');
            }
        }
        vm.$watch('appointmentModel.dateShow', function (newVal) {
            initStartTimeEnum();
        });
        vm.startTimeEnum = [];
        vm.endTimeEnum = [];
        initStartTimeEnum();
        initEndTimeEnum();
        function initStartTimeEnum() {
            if (vm.appointmentModel.dateShow &&
                vm.appointmentModel.dateShow.toLocaleDateString()=== vm.now.toLocaleDateString()) {
                var now_h = vm.now.getHours();
                now_h = now_h<10?'0'+now_h:now_h.toString();
                var now_m = vm.now.getMinutes()>=30?'30':'00';
                var now_string = now_h+':'+now_m;
                var now_index = vm.timeEnumList.indexOf(now_string);
                // vm.startTimeEnum =vm.timeEnumList.slice(now_index+1);
                // 开始时间包括当前时间
                vm.startTimeEnum =vm.timeEnumList.slice(now_index);
            } else {
                vm.startTimeEnum = vm.timeEnumList;
            }
        }
        function initEndTimeEnum() {
            if (vm.appointmentModel.startTime) {
                var start_index = vm.timeEnumList.indexOf(vm.appointmentModel.startTime);
                vm.endTimeEnum = vm.timeEnumList.slice(start_index+1);
                var nextTimeEnum = vm.timeEnumList.slice(0, start_index);
                vm.endTimeEnum = vm.endTimeEnum.concat(nextTimeEnum.map(function (val) {
                    return val+" (次日)"
                }));
            } else {
                vm.endTimeEnum = vm.timeEnumList;
            }
        }
        vm.sStartTime = function (item) {
            vm.appointmentModel.startTime=item;
            initEndTimeEnum()
        };
        vm.sEndTime = function (item) {
            vm.appointmentModel.endTimeShow = item;
        };

        //================================end time selector==================================

        vm.repeatList = [
            {value: 'MONTH', name: '月'},
            {value: 'WEEK', name: '周'},
            {value: 'DAY', name: '日'}
        ];
        vm.sCycleType = function (item) {
            vm.appointmentModel.cycleName=item.name;
            vm.appointmentModel.cycleType=item.value;
        };
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberForgotPasswordController', MemberForgotPasswordController);
    MemberForgotPasswordController.$inject = ['$state', '$scope', 'MemberAccountService', 'VerificationService', '$interval', '$uibModalInstance', 'MessageService'];

    function MemberForgotPasswordController($state, $scope, MemberAccountService, VerificationService, $interval, $uibModalInstance, MessageService) {
        /**
         * Created by vimpans on 2019/8/13
         */
        var vm = $scope;

        vm.canVerify = true;
        vm.counter = 0;

        vm.forgotModel = {};

        vm.getVerification = function () {
            if (!vm.forgotModel.phone) {return}
            VerificationService.get({phone:vm.forgotModel.phone, type: 'PASSWORD'},function (res) {
                countDownAction();
            }, function (error) {
                MessageService.error("获取验证码失败 ！"+(error.data.detail||''));
            })
        };

        function countDownAction() {
            vm.canVerify = false;
            vm.counter = 60;
            var interval = $interval(function () {
                vm.counter --;
                if (vm.counter <= 0) {
                    vm.counter = 0;
                    vm.canVerify = true;
                    $interval.cancel(interval);
                }
            },1000);
        }

        vm.forgotPassword = function () {
            MemberAccountService.forgotPassword(vm.forgotModel, function (res) {
                MessageService.success("密码重置成功 ！");
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error("密码重置失败 "+(error.data.detail||""));
            });
        };

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };
    }
})();

(function () {
    'use strict';
    angular
        .module('gwApp')
        .directive('vpEditor', vpEditor);

    function vpEditor() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                var id = attrs.id;
                var editor = new wangEditor(id);
                editor.onchange = function () {
                    // 从 onchange 函数中更新数据
                    scope.$apply(function () {
                        var html = editor.$txt.html();
                        ctrl.$setViewValue(html);
                    });
                };
                editor.create();
            }
        };
    }
})();

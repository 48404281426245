(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('MessageService', MessageService);

    MessageService.$inject = ['$uibModal', '$state'];

    function MessageService($uibModal, $state) {
        var service = {
            contentKnow: contentKnow,
            contentConfirm: contentConfirm,
            confirm: confirm,
            confirm2: confirm2,
            successConfirm: successConfirm,
            comment:comment,
            loading:loading,
            error:error,
            notice:notice,
            info:info,
            success:success,
            notify:notify


        };

        var settings = {
            title: 'The notice title.', // The notice's title.
            title_escape: false, // Whether to escape the content of the title. (Not allow HTML.)
            // text: 'The notice text.', // The notice's text.
            text_escape: false, // Whether to escape the content of the text. (Not allow HTML.)
            styling: "bootstrap3", // What styling classes to use. (Can be either jqueryui or bootstrap or bootstrap3.)
            // addclass: "", // Additional classes to be added to the notice. (For custom styling.)
            // cornerclass: "", // Class to be added to the notice for corner styling.
            // nonblock: false, // Create a non-blocking notice. It lets the user click elements underneath it.
            // nonblock_opacity: .2, // The opacity of the notice (if it's non-blocking) when the mouse is over it.
            history: false, // Display a pull down menu to redisplay previous notices, and place the notice in the history.
            // width: "300px", // Width of the notice.
            // min_height: "16px", // Minimum height of the notice. It will expand to fit content.
            type: "notice", // Type of the notice. "notice", "info", "success", or "error".
            icon: true, // Set icon to true to use the default icon for the selected style/type, false for no icon, or a string for your own icon class.
            // animation: "fade", // The animation to use when displaying and hiding the notice. "none", "show", "fade", and "slide" are built in to jQuery. Others require jQuery UI. Use an object with effect_in and effect_out to use different effects.
            // animate_speed: "slow", // Speed at which the notice animates in and out. "slow", "def" or "normal", "fast" or number of milliseconds.
            // opacity: 1, // Opacity of the notice.
            // shadow: true, // Display a drop shadow.
            // closer: true, // Provide a button for the user to manually close the notice.
            // closer_hover: true, // Only show the closer button on hover.
            // sticker: true, // Provide a button for the user to manually stick the notice.
            // sticker_hover: true, // Only show the sticker button on hover.
            // hide: true, // After a delay, remove the notice.
            delay: 2000 // Delay in milliseconds before the notice is removed.
                // mouse_reset: true, // Reset the hide timer if the mouse moves over the notice.
                // remove: true, // Remove the notice's elements from the DOM after it is removed.
                // insert_brs: true, // Change new lines to br tags.
                // stack: {"dir1": "down", "dir2": "left", "push": "bottom", "spacing1": 25, "spacing2": 25}
        };
        var current;

        function notice(content) {
            var hash = angular.copy(settings);
            hash.type = 'notice';
            hash.title = content;
            hash.nonblock = {
                nonblock: true,
                nonblock_opacity: .2
            };
            return this.notify(hash);
        };

        function info(content) {
            var hash = angular.copy(settings);
            hash.type = 'info';
            hash.title = content;
            hash.nonblock = {
                nonblock: true,
                nonblock_opacity: .2
            };
            return this.notify(hash);
        };

        function success(content) {
            var hash = angular.copy(settings);
            hash.type = 'success';
            hash.title = content;
            hash.nonblock = {
                nonblock: true,
                nonblock_opacity: .2
            };
            return this.notify(hash);
        };

        function error(content) {
            console.log("message.Service ----->info:"+content);
            var hash = angular.copy(settings);
            hash.type = 'error';
            hash.title = content;
            if (content == 'Access Denied') {
				$state.go('login');
				console.log("message.Service ----->accessDenied");
                error("用户已退出，请重新登录");
            } else {
                return this.notify(hash);
            }
        };

        function notify(hash) {
            if (current) {
                current.remove();
            }
            current = new PNotify(hash);
            return current;

            var modalInstance = $uibModal.open({
                index: 1000,
                template: '<div class="modal-body">' + //
                    '<div class="row" style="text-align:center;">' + //
                    '<h3>' + hash.title + '</h3>' + //
                    '</div>' + //
                    '</div>',
            });
        };

        function loading() {
            return $uibModal.open({
                template: '<i class="fa fa-5x fa-spinner fa-spin fa-white"></i>',
                backdrop: 'static',
                keyboard: false,
                windowClass: 'loading-window'
            });
        };

        function confirm(msg, confirmCallback, cancelCallback) {
            var ModalInstanceCtrl = ['$scope', '$uibModalInstance',
                function($scope, $uibModalInstance) {

                    $scope.ok = function() {
                        $uibModalInstance.close('ok');
                    };

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            ];

            var modalInstance = $uibModal.open({
                template: '<div class="modal-body">' + //
                '<div style="text-align:center; padding-bottom: 0">' + //
                '<h3 style="font-size: 36px; font-weight: 700; color: #292929; margin-bottom: 20px">' + msg + '</h3>' + //
                '</div>' + //
                '<div class="modal-footer" style="width: 100%;text-align: center;">' + //
                '<button class="btn btn-default" type="button" ng-click="cancel()" style="margin-right: 20px; padding: 10px 50px">取消</button>' + //
                '<button class="btn btn-primary normal-btn" type="button" ng-click="ok()" style="padding: 10px 50px">确认</button>' + //
                '</div>' +
                '</div>',

                controller: ModalInstanceCtrl
            });

            modalInstance.result.then(function() {
                if (typeof confirmCallback == 'function') {
                    confirmCallback();
                }
            }, function() {
                if (typeof cancelCallback == 'function') {
                    cancelCallback();
                }
            });

        };

        function contentConfirm(option, confirmCallback, cancelCallback) {

            console.log('option',option);

            var ModalInstanceCtrl = ['$scope', '$uibModalInstance',
                function($scope, $uibModalInstance) {

                    $scope.option = option;

                    $scope.showDescription = false;

                    $scope.showDescAction = function () {
                        $scope.showDescription=!$scope.showDescription
                    };

                    $scope.ok = function() {
                        $uibModalInstance.close('ok');
                    };

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            ];

            var modalInstance = $uibModal.open({
                template: '<div class="modal-body">' + //
                        '<div style="text-align:center; padding-bottom: 0">' + //
                            '<h3 style="font-size: 36px; font-weight: 700; color: #292929; margin-bottom: 20px">' + (option.title||"") + '</h3>' + //
                            '<p style="font-size: 20px; font-weight: 500px; color: #898989">' + (option.content||"") + '</p>' + //
                        '</div>' + //
                        '<div style="margin: 20px; padding: 12px; border-top: 1px solid #BEC4CB; color: #898989; font-size: 20px; text-align: center" ng-if="option.desc">'+option.desc+'</div>'+
                        '<div class="modal-footer" style="width: 100%;text-align: center;">' + //
                                '<button class="btn btn-default" type="button" ng-click="cancel()" style="margin-right: 20px;">'+(option.cancel||'取消')+'</button>' + //
                                '<button class="btn btn-primary normal-btn" type="button" ng-click="ok()">'+(option.issue||'确认')+'</button>' + //
                                '<div style="clear: both"></div>\n' +
                '                <p style="font-size: 18px; color: #AAAAAA; margin-top: 20px; cursor: pointer" ng-click="showDescAction()" ng-if="option.descTitle">'+option.descTitle+' <span class="iconfont icon-wenhao"></span></p>' +
                        '</div>' +
                        '<div style="text-align: left" ng-if="showDescription && option.descContent">\n' +
                '                <div class="appointment-modal-left" style="width: 100%">\n' +
                '                    <p>'+option.descContent+'</p>\n' +
                '                </div>\n' +
                '            </div>' +
                    '</div>',

                controller: ModalInstanceCtrl
            });

            modalInstance.result.then(function() {
                if (typeof confirmCallback == 'function') {
                    confirmCallback();
                }
            }, function() {
                if (typeof cancelCallback == 'function') {
                    cancelCallback();
                }
            });

        };

        function contentKnow(option) {

            var ModalInstanceCtrl = ['$scope', '$uibModalInstance',
                function($scope, $uibModalInstance) {

                    $scope.ok = function() {
                        $uibModalInstance.close('ok');
                    };

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            ];

            var modalInstance = $uibModal.open({
                template: '<div class="modal-body">' + //
                '<div style="text-align:center; padding-bottom: 0">' + //
                '<h3 style="font-size: 36px; font-weight: 700; color: #292929; margin-bottom: 20px">' + (option.title||"") + '</h3>' + //
                '<p style="font-size: 20px; font-weight: 500px; color: #898989">' + (option.content||"") + '</p>' + //
                '</div>' + //
                '<div class="modal-footer" style="width: 100%;text-align: center;">' + //
                '<button class="btn btn-primary normal-btn" type="button" ng-click="ok()">'+(option.issue||'确认')+'</button>' + //
                '</div>' +
                '</div>',

                controller: ModalInstanceCtrl
            });

            modalInstance.result.then();

        };

        function confirm2(msg, confirmCallback) {
            var ModalInstanceCtrl = ['$scope', '$uibModalInstance',
                function($scope, $uibModalInstance) {

                    $scope.ok = function() {
                        $uibModalInstance.close('ok');
                    };
                }
            ];

            var modalInstance = $uibModal.open({
                template: '<div class="modal-body">' + //
                    '<div class="row" style="text-align:center;">' + //
                    '<h3>' + msg + '</h3>' + //
                    '</div>' + //
                    '<div class="row">' + //
                    '<button class="btn btn-primary pull-right" ng-click="ok()">确认</button>' + //
                    '</div>' + //
                    '</div>',
                controller: ModalInstanceCtrl
            });

            modalInstance.result.then(function() {
                if (typeof confirmCallback == 'function') {
                    confirmCallback();
                }
            }, function() {
                if (typeof cancelCallback == 'function') {
                    cancelCallback();
                }
            });

        };

        function successConfirm(msg, confirmCallback) {
            var ModalInstanceCtrl = ['$scope', '$uibModalInstance',
                function($scope, $uibModalInstance) {

                    $scope.ok = function() {
                        $uibModalInstance.close('ok');
                    };

                }
            ];

            var modalInstance = $uibModal.open({
                template: '<div class="modal-body">' + //
                    '<div class="row" style="text-align:center;">' + //
                    '<h3 style="cursor:pointer;" ng-click="ok()">' + msg + '</h3>',
                controller: ModalInstanceCtrl
            });
            modalInstance.result.then(function() {
                if (typeof confirmCallback == 'function') {
                    confirmCallback();
                }
            });
        };

        function comment(msg, confirmCallback, cancelCallback, commentMust) {
            var ModalInstanceCtrl = ['$scope', '$uibModalInstance', '$timeout',
                function($scope, $uibModalInstance, $timeout) {
                    $scope.ok = function(comment) {
                        if (commentMust && !comment) {
                            // return self.error('评论必填');
                            $scope.error = true;
                            return;
                        }
                        $scope.error = false;
                        $uibModalInstance.close(comment);
                    };
                    if (commentMust) {
                        $scope.placeholderMsg = "请输入评论(必填)";
                    } else {
                        $scope.placeholderMsg = "请输入评论(可空)";
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $timeout(function() {
                        $scope.$apply();
                    });
                }
            ];

            var modalInstance = $uibModal.open({
                template: '<div class="modal-body">' + //
                    '<div class="row" style="text-align:center;">' + //
                    '<h3>' + msg + '</h3>' + //
                    '<input type="text" class="form-control highlight" ng-model="comment" placeholder="{{placeholderMsg}}">' + //
                    '<span style="color:red;float:left;margin-left:10px;" ng-show="error">必填</span>' + //
                    '</div>' + //
                    '<div class="row" style="padding-top: 10px;">' + //
                    '<button class="btn btn-warning pull-right" ng-click="cancel()">取消</button>' + //
                    '<button class="btn btn-primary pull-right" ng-click="ok(comment)">确认</button>' + //
                    '</div>' + //
                    '</div>',
                controller: ModalInstanceCtrl
            });

            modalInstance.result.then(function(comment) {
                if (typeof confirmCallback == 'function') {
                    confirmCallback(comment);
                }
            }, function() {
                if (typeof cancelCallback == 'function') {
                    cancelCallback();
                }
            });
        };
        return service;
    }

})();

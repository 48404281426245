(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberAppointmentRecordController', MemberAppointmentRecordController);
    MemberAppointmentRecordController.$inject = ['$state', 'entity', '$scope', '$uibModalInstance', 'OfficeItemService'];

    function MemberAppointmentRecordController($state, entity, $scope, $uibModalInstance, OfficeItemService) {
        /**
         * Created by vimpans on 2019/7/29
         */
        var vm = $scope;

        if (entity) {
            vm.companyId = entity.companyId;
        }

        vm.pageparams = {
            page: 0,
            size: 10,
            count: 0,
            startTime: null,
            endTime: null
        };
        vm.startTimeSel = false;
        vm.endTimeSel = false;

        vm.reloadDataAction = function () {
            initDataAction();
        };

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.appointmentRecords = [];

        initDataAction();
        function initDataAction() {
            var params = {cId: vm.companyId, page: vm.pageparams.page-1, size: vm.pageparams.size};
            if (vm.pageparams.startTime) {
                params.start = vm.pageparams.startTime.toStartISOString();
            }
            if (vm.pageparams.endTime) {
                params.end = vm.pageparams.endTime.toEndISOString();
            }
            OfficeItemService.queryByCompany(params, function (res) {
                vm.appointmentRecords = res.data.map(function (val) {
                    var start = new Date(val.startTime);
                    var end = new Date(val.endTime);
                    var now = new Date();
                    var beforeStart = (now-start)<0;
                    var afterEnd = (now-end)>0;
                    if (beforeStart) {
                        val.stateString = '未开始';
                    } else if (afterEnd) {
                        val.stateString = '已结束';
                    } else {
                        val.stateString = '进行中';
                    }
                    return val;
                });
                vm.pageparams.count = res.info.count;
            })
        }

        vm.pageChangeAction = function () {
            initDataAction();
        }
    }
})();

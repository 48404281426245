(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ActivitiesController', ActivitiesController);
    ActivitiesController.$inject = ['$state', '$scope', 'ActivityService', '$filter', 'MessageService', 'Principal', '$rootScope'];

    function ActivitiesController($state, $scope, ActivityService, $filter, MessageService, Principal, $rootScope) {
        /**
         * Created by vimpans on 2019/7/11
         */
        var vm = $scope;

        vm.recentlyList = [];
        vm.historyList = [];
        vm.recommendModel = {};
        vm.recentlyPage = 1;
        vm.recentlySize = 10;
        vm.recentlyCount = 0;
        vm.historyPage = 1;
        vm.historySize = 10;
        vm.historyCount = 0;
        initRecentlyAction();
        initHistoryAction();

        vm.userModel = null;
        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
            })
        }

        function initRecentlyAction() {
            ActivityService.findRecently({
                page: vm.recentlyPage-1,
                size: vm.recentlySize
            }, function (res) {
                vm.recentlyList = res.data.map(function (val) {
                    val.insertHtml = '<br>'+
                        val.name+'<br>时间：'+
                        $filter('date')(val.startTime, 'yyyy-MM-dd HH:mm')+'-'+
                        $filter('date')(val.endTime, 'yyyy-MM-dd HH:mm')+'<br>地点：'+
                        val.addrProvince+
                        val.addrCity+
                        val.addrDistrict+
                        val.addrAddress;
                    return val;
                });
                vm.recommendModel = vm.recentlyList[0]||{};
                vm.recentlyCount = res.info.count;
            });
        }
        function initHistoryAction() {
            ActivityService.findHistory({
                page: vm.historyPage-1,
                size: vm.historySize
            }, function (res) {
                vm.historyList = res.data;
                vm.historyCount = res.info.count;
            })
        }
        vm.recentlyPageChange = function () {
            initRecentlyAction();
        };
        vm.historyPageChange = function () {
            initHistoryAction();
        };

        vm.applyAction = function (activity) {
            if (!vm.userModel) {
                $rootScope.$emit("requestLogin");
                return;
            }
            ActivityService.apply({activityId: activity.id}, function (res) {
                MessageService.contentKnow({
                    title: '报名成功',
                    content: activity.insertHtml,
                    issue: '知道了'
                })
            }, function (error) {
                MessageService.contentKnow({
                    title: '报名失败',
                    content: error.data.detail,
                    issue: '知道了'
                })
            })
        };
    }
})();

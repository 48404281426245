(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MemberAccessRecordsController', MemberAccessRecordsController);
    MemberAccessRecordsController.$inject = ['$state', '$scope', 'entity', '$uibModalInstance', 'WgaccessService'];

    function MemberAccessRecordsController($state, $scope, entity, $uibModalInstance, WgaccessService) {
        /**
         * Created by vimpans on 2019/8/14
         */
        var vm = $scope;

        if (entity) {
            vm.companyId = entity.companyId;
            vm.buildingId = entity.buildingId;
        }

        vm.pageparams = {
            page: 0,
            size: 10,
            count: 0,
            startTime: null,
            endTime: null
        };
        vm.startTimeSel = false;
        vm.endTimeSel = false;

        vm.reloadDataAction = function () {
            initDataAction();
        };

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.accessRecords = [];

        initDataAction();
        function initDataAction() {
            var params = {companyId: vm.companyId, page: vm.pageparams.page-1, size: vm.pageparams.size, buildingId: vm.buildingId};
            if (vm.pageparams.startTime) {
                params.start = vm.pageparams.startTime.toStartISOString();
            }
            if (vm.pageparams.endTime) {
                params.end = vm.pageparams.endTime.toEndISOString();
            }
            WgaccessService.queryByCompany(params, function (res) {
                vm.accessRecords = res.data;
                vm.pageparams.count = res.info.count;
            })
        }

        vm.pageChangeAction = function () {
            initDataAction();
        }
    }
})();
